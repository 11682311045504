import React, { Component } from 'react';
import * as dialogService from './dialogService';
import './css/dialog.css';
import ReactHtmlParser from 'react-html-parser';

class Dialog extends Component {
    state = {
        open: false,
        data: null
    }
    constructor() {
        super();
        this.call = this.call.bind(this);
        this.close = this.close.bind(this);
        this.hide = this.hide.bind(this);
    }
    close = (e) => {
        this.hide()
    }
    prevent = (e) => {
        e.stopPropagation();
        return false;
    }

    showDialog() {
        if (this.state.open) {
            return (

                <div className="dialog-holder" onClick={this.close}>
                    <div className="dialog" onClick={this.prevent}>
                        <div className="title">{this.state.data.title}</div>
                        <div className="body">{ReactHtmlParser(this.state.data.body)}</div>
                        <div className="actions">
                            {this.state.data.actions.map(action => (
                                <button type="button" key={action.label} className={'btn ' + action.class} onClick={(e) => { action.action() }}>{action.label}</button>
                            ))}
                        </div>
                    </div>

                </div>

            );
        } else {
            return '';
        }
    }

    call(data) {
        this.setState({ open: true, data: data })
    }

    hide() {
        this.setState({ open: false, data: null })
    }


    render() {
        return this.showDialog();

    }
    componentDidMount() {
        dialogService.registered(this.call, this.hide);

    }
}

export default Dialog;