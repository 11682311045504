import React, { Component } from 'react';
import Rating from '@material-ui/lab/Rating';
import * as posService from "../../services/posService";
import * as cookie from '../../cookie';
import Topbar from "../topbar/topbar";
import "./css/feedback.css";
import Button from '@material-ui/core/Button';
import {withStyles} from '@material-ui/core/styles';


const PrimaryButton = withStyles((theme) => ({
	root: {
	  color: theme.palette.getContrastText(window.theme['primary-600']),
	  backgroundColor: window.theme['primary-600'],
	  fontFamily:'inherit',
      fontSize:'16px',
	  '&:hover': {
		backgroundColor: window.theme['primary-400'],
	  },
	  '&:disabled': {
		backgroundColor: '#d4d4d4',
	  },
	},
}))(Button);
class Feedback extends Component {
    state = {
        value: 0,
        orderid:0
    }
    constructor() {
        super();
        this.submitFeedback = this.submitFeedback.bind(this);
    }
    submitFeedback = (e) => {
        let review = (document.getElementById('review')).value;

        posService.call(
            'SubmitCustomerFeedback',
            'post', {}, {}, {
            "vm": {
                "APIToken": posService.posData.Code,
                "OrderID": window.getURLParameter('orderid', this.props.location.search),
                "CustomerID": cookie.get('customerid'),
                "Feedback": review,
                "Rating": this.state.value,
                "CompanyID": posService.posid
            }
        }).then(function (res) {
            cookie.del('orderid');
            cookie.del('tableNo');
            if(window.feedbackLink){
                cookie.del('customerid');
                cookie.del('customerName');
                cookie.del('customerMobile');
                cookie.del('customerCountryCode');
                let manifest = window.manifest;
                var homeurl =  process.env.REACT_APP_HOME + '/' + manifest.BusinessUnitUrl;
                if (manifest.LitePWAEnable) {
                    homeurl = process.env.REACT_APP_PWA + '/' + manifest.OrderingStoreSlug + '/landing'
                }
                window.location.href = homeurl;
            }



            (document.querySelector('.get-feedback')).classList.add('hide');
            (document.querySelector('.after-feedback')).classList.remove('hide');
            
            // do on submit
        }).catch(function (res) {
            //alert('ERROR')
        });




    }

    render() {

        return (
            <div className="feedback">
                <Topbar {...this.props}/>
                <div className="body">
                    <div className="_logo">
                        <img src={posService.posData.BannaerImageUrl} alt="logo" className="logo-image" />
                    </div>
                    <div className="get-feedback">
                        <h1>Thank you for your visit.</h1>
                        <h3>Your feedback is our reward!</h3>
                        <div className="stars">
                            <Rating size="large"
                                name="hover-feedback"
                                value={this.state.value}
                                precision={0.5}
                                onChange={(event, newValue) => {
                                    this.setState({ value: newValue })
                                    //setValue(newValue);
                                }}
                                onChangeActive={(event, newHover) => {
                                    //setHover(newHover);
                                }}
                            />
                        </div>

                        <textarea name="review" id="review" cols="30" rows="10"></textarea>

                        <PrimaryButton className="btn-center submit-review" onClick={this.submitFeedback}>Submit</PrimaryButton>
                    </div>
                    <div className="after-feedback hide">
                        <h1 >Happy to serve you again.</h1>
                    </div>
                </div>

            </div>
        );
    };





    componentDidMount() {
        cookie.del('orderid');
        cookie.del('tableNo');
    }

}

export default Feedback;