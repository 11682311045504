import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import * as ecomService from '../../services/ecomService';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import FilterListIcon from '@material-ui/icons/FilterList';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import * as cookie from "../../cookie";
import './css/category.css';
import RestaurantIcon from '@material-ui/icons/Restaurant';

class Categories extends Component {

    state = {
        categories: [],
        subCategories: [],
        id: this.props.match.params.id,
        parentid: this.props.match.params.id,
        subid: this.props.match.params.subid,
        search: window.getURLParameter('search', this.props.location.search),
        right: false,
        tree: []
    }

    toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        this.setState({ ...this.state, [anchor]: open });
    }

    searchUrl() {
        let key = (document.getElementById('psearch')).value;
        this.setState({ 'search': key })
        if (key === '') {
            this.clearSearch();
        } else {
            if (this.state.id !== undefined) {
                this.props.history.push(`/${window.app_code}/products/${this.state.id}?search=${key}`);
            } else {
                this.props.history.push(`/${window.app_code}/products?search=${key}`);
            }
        }

    }

    clearSearch() {
        this.setState({ 'search': '' });
        (document.getElementById('psearch')).value = '';
        if (this.state.id !== undefined) {
            this.props.history.push(`/${window.app_code}/products/${this.state.id}`);
        } else {
            this.props.history.push(`/${window.app_code}/products`);
        }

    }

    isSearch() {
        if (this.state.search !== '' && this.state.search != null) {
            return ''
        } else {
            return 'hide'
        }
    }

    isRootCategory() {
        return new Promise((resolve) => {
            if (this.state.id === undefined) {
                resolve(null);
            } else {
                let rootCategory = false;
                this.state.categories.forEach(category => {
                    if (category.id === this.state.id) {
                        rootCategory = true;
                    }
                });
                resolve(rootCategory);
            }
        });

    }

    getCustomerMobileNo() {
        let code = cookie.get('customerCountryCode').toString();
        let number = cookie.get('customerMobile').toString();
        if (code != null) {
            if (number.search('0000') == 0) {
                return null;
            } else {
                return code + number;
            }
        } else {
            return null;
        }
    }
    
    isUser() {
        if (this.getCustomerMobileNo() == null) {
            return 'hide'
        } else {
            return ''
        }
    }

    getCategories() {
        ecomService.getCategories({ "parent_id": "", "access_token": "", "calltime": "" }).then((res) => {
            this.setState({ categories: res.data.categories });
            this.getSubCategories();
        });
    }

    getSubCategories() {
        return new Promise(resolve => {
            let _return = false;
            this.state.categories.forEach(category => {
                if (this.state.id == category.id) {
                    if (category.total_child !== 0) {
                        _return = true;
                        ecomService.getCategories({ "parent_id": this.state.id, "access_token": "", "calltime": "" }).then((res) => {
                            this.setState({ subCategories: res.data.categories });
                            resolve();
                        });
                    } else {
                        this.setState({ subCategories: [] });
                        _return = true;
                        resolve();
                    }
                }

            });
            if (!_return) {
                resolve()
            }
        });

    }

    getCategoryTree() {
        ecomService.getCategoriesTree( { "access_token": "" }).then((res) => {
            this.setState({ tree: res.data });
            //console.log('category tree', res.data);
        });
    }

    getcategoryClass(category) {
        if(category.isRedemable){
            return 'category ' + this.isUser();
        } else {
            return 'category ';
        }
        
    }

    getCategoryTreeClass(category) {
        let classes = ['cat'];
        if (this.state.parentid == category.id) {
            classes.push('selected')
        }

        if (category.chieldren.length > 0) {
            classes.push('have')
        }
        if (category.name == 'Reward') {
            classes.push(this.isUser())
        }


        return classes.join(' ');
    }

    render() {
        //this.getCategories();
        return (
            <div className="category">
                <div className="categories">
                    {this.state.categories.map(category => (
                        <div className={this.getcategoryClass(category)} key={category.id}>
                            <Link to={`/${window.app_code}/products/${category.id}`}>
                                <p className={(this.state.parentid == category.id) ? 'selected' : ''}>{category.name}</p>
                            </Link>
                        </div>
                    ))}
                </div>
                <div className="sub-categories">
                    {this.state.subCategories.map(category => (
                        <div className="category" key={category.id}>
                            <Link to={`/${window.app_code}/products/${category.id}`}>
                                <p className={(this.state.id == category.id) ? 'selected' : ''}>{category.name}</p>
                            </Link>
                        </div>
                    ))}
                </div>

                <div className="search-bar">
                    <div className="product-search">
                        <p className="search-icon"><SearchIcon /></p>
                        <input type="text" id="psearch" defaultValue={this.state.search} onKeyUp={() => { this.searchUrl() }} />
                        <p className={"clear-search " + this.isSearch()} onClick={() => { this.clearSearch() }}><CloseIcon /></p>
                    </div>
                    <div className="categories-icon" onClick={this.toggleDrawer('right', true)}>
                        <FilterListIcon />
                    </div>
                </div>

                <div className="menuBtn" onClick={this.toggleDrawer('right', true)} ><RestaurantIcon />Menu</div>

                <Drawer className="app-drawer-container" anchor="right" open={this.state.right} onClose={this.toggleDrawer('right', false)}>
                    <div className="category-tree">
                        <div className="header">
                            <p>Browse Categories</p>
                            <p className="close" onClick={this.toggleDrawer('right', false)}><CloseIcon /></p>
                        </div>
                        <ul className="tree">
                            {this.state.tree.map(category => (
                                <li className="category" key={category.id} >
                                    <Link to={`/${window.app_code}/products/${category.id}`} onClick={this.toggleDrawer('right', false)}>
                                        <div className={this.getCategoryTreeClass(category)}>
                                            <div className="icon"><PlayCircleOutlineIcon /></div>
                                            <p>{category.name}</p>
                                        </div>
                                    </Link>
                                    <ul className="subs">
                                        {category.chieldren.map(category => (
                                            <li className="sub-category" key={category.id} >
                                                <Link to={`/${window.app_code}/products/${category.id}`} onClick={this.toggleDrawer('right', false)}>
                                                    <p className={(this.state.parentid == category.id) ? 'selected' : ''}>{category.name}</p>
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>





                                </li>
                            ))}
                        </ul>

                        {/* <div className="links">
                            <Link className="link" to={`/${window.app_code}/products`} >
                                <div className="icon"><HomeIcon /></div>
                                <div className="label">Home</div>
                            </Link>
                            <Link className="link" to={`/${window.app_code}/payment`} >
                                <div className="icon"><PaymentIcon /></div>
                                <div className="label">Payment</div>
                            </Link>
                            <p className={"link " + this.isUser()} onClick={() => { this.goToRewards() }}>
                                <div className="icon"><RedeemIcon /></div>
                                <div className="label">Rewards</div>
                            </p>

                        </div>
                        <div className="copy">
                            {posService.posData.CopyrightText}
                        </div> */}
                    </div>
                </Drawer>
            </div>
        );
    };
    componentDidMount() {
        this.getCategoryTree();
        this.getCategories();
        let _this = this;
        this.props.history.listen((location, action) => {

            setTimeout(function () {
                _this.setState({
                    categories: _this.state.categories,
                    id: _this.props.match.params.id,
                    parentid: _this.props.match.params.id,
                    subid: _this.props.match.params.subid
                });

                _this.getSubCategories().then(() => {
                    _this.isRootCategory().then(rootCategory => {
                        if (rootCategory === false) {
                            if (_this.state.subCategories.length > 0) {
                                _this.setState({
                                    parentid: _this.state.subCategories[0].parent_id
                                });
                            }
                        }
                    });
                })
            }, 50)

        });
    }

}

export default Categories;