import { Route, Redirect } from 'react-router-dom';
import * as auth from '../../services/authService';
import * as cookie from '../../cookie';

window.getURLParameter = (e, queryString) => {
    return decodeURIComponent((new RegExp("[?|&]" + e + "=([^&;]+?)(&|#|;|$)").exec(queryString) || [, ""])[1].replace(/\+/g, "%20")) || null
}
const ProtectedRoute = ({ path, component: Component, render, ...rest }) => {
    return (

        <Route  {...rest} render={props => {
            if (cookie.get('tableNo') === null) {
                let slug = (window.app_code === undefined) ? 'scan' : window.app_code;
                return <Redirect to={`/${slug}/scan`} />
            } else if (auth.getUser() === null) {
                if (window.app_code === undefined) {
                    cookie.del('tableNo');
                    let slug = (window.app_code === undefined) ? 'scan' : window.app_code;
                    return <Redirect to={`/${slug}/scan`} />
                } else {
                    let tableCode = btoa('dts_'+cookie.get('tableNo'));
                    return <Redirect to={`/${window.app_code}/login?tableno=${tableCode}`} />
                }
            } else {
                return Component ? <Component {...props} /> : render(props);
            }

        }} />
    );
}

export default ProtectedRoute;
