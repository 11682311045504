import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import * as posService from '../../services/posService';
import './css/topbar.css';
import * as cookie from "../../cookie";

import Drawer from '@material-ui/core/Drawer';
import MenuIcon from '@material-ui/icons/Menu';
import HomeIcon from '@material-ui/icons/Home';
import PaymentIcon from '@material-ui/icons/Payment';
import RedeemIcon from '@material-ui/icons/Redeem';
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu';
import * as dialogService from './../dialog/dialogService';
import Button from '@material-ui/core/Button';
import {withStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const DialogButton = withStyles((theme) => ({
	root: {
	  color: theme.palette.getContrastText(window.theme['primary-600']),
	  backgroundColor: window.theme['primary-600'],
	  fontFamily:'inherit',
	  '&:hover': {
		backgroundColor: window.theme['primary-400'],
	  },
	  '&:disabled': {
		backgroundColor: '#d4d4d4',
	  },
	},
}))(Button);
class Topbar extends Component {
    state = {
        left: false,
        feedbackPage:false,
        paymentUrl:false,
        redirectDialog:false
    }

    constructor() {
        super();
        this.toggleDrawer = this.toggleDrawer.bind(this);
    }
    toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        this.setState({ ...this.state, [anchor]: open });
        console.log(this.state)
    }

    getCustomerMobileNo() {
        let code = cookie.get('customerCountryCode').toString();
        let number = cookie.get('customerMobile').toString();
        if (code != null) {
            if (number.search('0000') == 0) {
                return null;
            } else {
                return code + number;
            }
        } else {
            return null;
        }
    }


    goToRewards() {
        window.location.href = window.manifest.LoyaltyTransactionAppURL + '/' + btoa('|' + this.getCustomerMobileNo() + ':abc123') + '?callbackurl=' + encodeURIComponent(btoa(window.location.href))
    }

    goToPayment(){
        if(cookie.get('orderid') != null && cookie.get('orderid') !== '0'){
            this.props.history.push(`/${window.app_code}/payment`);
        } else {
            if (window.table.PaymentOption === '1') {
                alert('You have no order to pay.')
            } else {
                this.props.history.push(`/${window.app_code}/payment`);
            }
            
        }
    }

    isUser() {
        if (this.getCustomerMobileNo() == null) {
            return 'hide'
        } else {
            return ''
        }
    }

    goHome() {
        let manifest = window.manifest;
        var homeurl =  process.env.REACT_APP_HOME + '/' + manifest.BusinessUnitUrl;
        if (manifest.LitePWAEnable) {
            homeurl = process.env.REACT_APP_PWA + '/' + manifest.OrderingStoreSlug + '/landing'
        }
        window.homeurl = homeurl;
        this.setState({redirectDialog:true,'left': false});
        //this.toggleDrawer()
    }

    payLabel() {
        if (window.table.PaymentOption == '1') {
            return 'Pay Now';
        } else {
            return 'Open Tab'
        }
    }

    isPage(page){
		return window.location.pathname.search(`/${window.app_code}/${page}`) === 0;
	}

    homeIconClick(e) {

        if (this.isPage('feedback')) {
            e.preventDefault();
            e.stopPropagation();
            this.goHome();
        }
    }

    drawerClass(){
        let cls = ['icon-btn'];
        if(this.state.feedbackPage){
            cls.push('hide');
        }
        

        if(window.paymentLink){
            cls.push('hide');
        }
        return cls.join(' ');
    }
    render() {

        return (
            <div className="topbar">
                <IconButton className={this.drawerClass()} onClick={this.toggleDrawer('left', true)}><MenuIcon /></IconButton>
                <Link to={`/${window.app_code}/products`}>
                    <IconButton className="icon-btn" onClick={(e) => { this.homeIconClick(e) }}><HomeIcon /></IconButton>
                </Link>
                
                <h1 className="app-name">{window.manifest.name}</h1>




                <Drawer className="app-drawer-container" anchor="left" open={this.state.left} onClose={this.toggleDrawer('left', false)}>
                    <div className="app-drawer">
                        <div className="header">
                            <img src={posService.posData.BannaerImageUrl} alt="logo" className="logo-image" />
                        </div>
                        <div className="links">
                            <div className="link" onClick={() => { this.toggleDrawer('left', false); this.goHome() }} >
                                <div className="icon"><HomeIcon /></div>
                                <div className="label">Home</div>
                            </div>
                            <Link className="link" to={`/${window.app_code}/products`} onClick={this.toggleDrawer('left', false)} >
                                <div className="icon"><RestaurantMenuIcon /></div>
                                <div className="label">Order Now</div>
                            </Link>
                            
                            <div className="link" onClick={(e) => { this.goToPayment() }}>
                                <div className="icon"><PaymentIcon /></div>
                                <div className="label">{this.payLabel()}</div>
                            </div>



                            <div className={"link " + this.isUser()} onClick={() => { this.goToRewards() }}>
                                <div className="icon"><RedeemIcon /></div>
                                <div className="label">My Loyalty</div>
                            </div>

                        </div>
                        <div className="copy">
                            <p>{posService.posData.CopyrightText}</p>
                        </div>
                    </div>
                </Drawer>

                <Dialog
                    open={this.state.redirectDialog}
                    TransitionComponent={Transition}
                    keepMounted
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title">{"Alert!"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                        You are about to leave Dine-in app and will be redirected to the home page of the restaurant primary app. Choose "Proceed" to continue else "Discard"
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <DialogButton onClick={()=>{this.setState({redirectDialog:false});}} >Discard</DialogButton>
                        <DialogButton onClick={()=>{
                            this.setState({redirectDialog:false});
                            window.location.href = window.homeurl;
                        }} >Proceed</DialogButton>
                    </DialogActions>
                </Dialog>


            </div>


        );
    }

    componentDidMount(){
        if (window.feedbackLink) {
            this.setState({feedbackPage:true});
        }else {
            this.setState({feedbackPage:false});
        }

        if (window.paymentLink) {
            this.setState({paymentUrl:true});
        }else {
            this.setState({paymentUrl:false});
        }

        this.props.history.listen((location, action) => {
            if (this.isPage('feedback')) {
                this.setState({feedbackPage:true});

            } else {
                this.setState({feedbackPage:false});
            }
        });
    }
}

export default Topbar;