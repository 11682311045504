import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './css/home.css';

class Home extends Component {
    state = {}
    render() {
        return (
            <div className="home">
                <img src="restoNxt-logo.png" alt="RestoNxt" />
            </div>

        );
    }
    componentDidMount() {
        console.log(this.props.match.params.appcode)
    }
}

export default Home;