import x2js from 'x2js';
import * as aspService from './aspService';
import * as authService from './authService';
import * as cookie from '../cookie';
var apiDomain = process.env.REACT_APP_POS_DOMAIN + '/posservice.asmx/';
//var CACHE = {};
export var posid = 40303;
export var posData = null;
window.feedbackLink = false;
window.paymentLink = false;
export var prevTable = null;
export var prevOrder = null;

window.currencySymbols = { "USD": "$", "EUR": "€", "CRC": "₡", "GBP": "£", "ILS": "₪", "INR": "₹", "JPY": "¥", "KRW": "₩", "NGN": "₦", "PHP": "₱", "PLN": "zł", "PYG": "₲", "THB": "฿", "UAH": "₴", "VND": "₫" }

/* function findCache(api,data){
    return new Promise((resolve,reject)=>{
        let _cache = false;
        if(CACHE[api] !== undefined ){
            CACHE[api].queries.forEach(query=>{
                if(JSON.stringify(query.data) === JSON.stringify(data)){
                    _cache =true;
                    resolve(query.response);
                }
            })
        }
        if(!_cache){
            reject(false);
        }
    });
}
function setCache(api,data,response){
    if(CACHE[api] === undefined){
        CACHE[api] = {};
    }
    if(CACHE[api].queries === undefined){
        CACHE[api].queries = [];
    }
    CACHE[api].queries.push({data:data,response:response});
} */



function makeQueryString(urldata) {
    return Object.keys(urldata).map(function(key) {
        return encodeURIComponent(key) + '=' + encodeURIComponent(urldata[key]);
    }).join('&');
}

function remove__text(data) {
    Object.keys(data).forEach(key => {
        if (key === '__text') {
            data = data[key];
        } else {
            if (typeof(data[key]) == 'object') {
                data[key] = remove__text(data[key]);
            }
        }
    });
    return data;
}

export function call(api, method, headers, urldata, data) {
    return new Promise((resolve, reject) => {
        let url = apiDomain + api;
        let settings = {
            headers: {},
            "method": method,
            "async": true,
            "mode": 'cors',
        }
        if (method.toLowerCase() === 'post') {
            settings.headers = {
                "Content-Type": "application/json",
                "SOAPAction": ''
            }
            settings.cache = 'no-cache';
            settings.body = JSON.stringify(data);
        } else {
            urldata = Object.assign(urldata, data);
            url += '?' + makeQueryString(urldata);
        }
        settings.headers = Object.assign(settings.headers, headers);
        fetch(url, settings)
            .then(res => res.text())
            .then(data => {
                let __res = (new x2js()).xml2js(data);
                __res = remove__text(__res);
                resolve(__res);
            }).catch(() => { reject() });
    })
}

export function sendReviewLink(reviewLink){
    return new Promise((resolve, reject) => {
        call('SendReviewLink', 'post', {}, {}, {
            req: {
                "APIToken": posData.Code,
                "OrderID": cookie.get('orderid'),
                "CustomerID": cookie.get('customerid'),
                "PhoneCountryCode": cookie.get('customerCountryCode'),
                "Mobile": cookie.get('customerMobile'),
                "ReviewLink": reviewLink
            }
        }).then((res) => {
            resolve();
        }).catch(() => { reject() })

    })
}

export function requestCheckout(reviewLink) {
    return new Promise((resolve, reject) => {
        call('RequestCheckout', 'get', {}, {
            APIToken: posData.Code,
            TableID: cookie.get('tableNo'),
            r: (new Date()).getTime()
        }, {}).then((res) => {
            if (res.Result.success === 'Yes') {
                //sendReviewLink(reviewLink).then(()=>{resolve()});
            } else {
                reject('POS CHECKOUT ERROR')
            }
        });
    })
}

export function getTableList(){
    return new Promise((resolve,reject)=>{
        let data = {
            APIToken: posData.Code,
            POSID: posData.POSID,
            r: new Date().getTime(),
        }
        /* findCache('GetTableList',data).then(response=>{
            resolve(response);
        }).catch(()=>{
            call("GetTableList", "get", {}, data, {}).then((response) => {
                setCache('GetTableList',data,response);
                resolve(response);
            }).catch(reject);
        }); */
        call("GetTableList", "get", {}, data, {}).then((response) => {
            resolve(response);
        }).catch(reject);
    })
}

export function getTableStatus(){
    return new Promise((resolve,reject)=>{
        getTableList().then(res=>{
            let tables = res.Result.Table1;
            if (!Array.isArray(tables)) {
                tables = [tables];
            }
            let tableno = cookie.get("tableNo");
            let activeTable = false;
            tables.forEach((table) => {
                if (tableno === table.TableMasterID && table.IsActive === '1') {
                    activeTable = true;
                    window.table = table;
                }
            });

            if (!activeTable) {
                let code = (window.app_code !== undefined) ? window.app_code : 'scan';
                window.location.href = window.location.origin + '/' + code + '/scan';
            }
            resolve(window.table);
        });
    });
}

function addThemeCss() {
    let _theme = document.createElement('link');
    _theme.href = `/${window.app_code}.theme.css`;
    _theme.rel = `stylesheet`;
    document.head.appendChild(_theme);
}


function checkPayLink(){
    return new Promise((resolve,reject)=>{
        let payData = window.getURLParameter('pay', window.location.search);
        if(payData != null){
            payData = atob(payData).split('|');
            cookie.set('orderid',payData[0])
            cookie.set('tableNo',payData[1]);
            window.tableNo = payData[1];
            authService.login(payData[4],payData[3],payData[2]).then(()=>{
                window.opentapalert = true;
                window.paymentLink = true;
                window.payOrderID = payData[0];
                resolve(true);
            });
        } else {
            resolve(false);
        }
    })
}

function checkFeedbackLink(){
    return new Promise((resolve,reject)=>{
        let review = window.getURLParameter('review', window.location.search);
        if(review != null){
            review = atob(review).split('|');
            cookie.set('orderid',review[0])
            cookie.set('tableNo',review[1]);
            window.tableNo = review[1];
            authService.login(review[4],review[3],review[2]).then(()=>{
                window.opentapalert = true;
                window.feedbackLink = true;
                resolve(true);
            });
        } else {
            resolve(false);
        }
    })
}


function getTable(){
    return new Promise((resolve,reject)=>{
        if (cookie.get('tableNo') === null) {
            let tableCode = window.getURLParameter('tableno', window.location.search);
            if (tableCode !== null) {
                let _tcode = atob(tableCode);
                if (_tcode.search('dts_') === 0) {
                    let tn = _tcode.replace('dts_', '');
                    cookie.set('tableNo', tn);
                    resolve(true);
                } else {
                    resolve(false);
                }
            } else {
                resolve(false);
            }
        } else {
            window.tableNo = cookie.get('tableNo');
            resolve(true);
        }
    })
}

function getPosData(posid){
    return new Promise((resolve,reject)=>{
        call('GetCode', 'get', {}, {
            ID: btoa(posid + "|" + (new Date()).getTime())
        }, {}).then((data) => {
            resolve(data.Result);
            /* posData = data.Result;
            posData.POSID = posid;
            window.logo = data.Result.BannaerImageUrl
            localStorage.setItem(posData.POSID+'_POSDATA',JSON.stringify(posData));
            resolve(data.Result);
            aspService.get_ecommerce_info().then(res => {
                resolve(true);
            }); */
        })
    });
}


function makeStart(){
    return new Promise((resolve,reject)=>{
        aspService.get_manifest(window.app_code).then((manifest) => {
            posid = manifest.POSID;
            let localPosData = localStorage.getItem(posid+'_POSDATA');
            if(localPosData !== null){
                posData = JSON.parse(localPosData);
                window.logo = posData.BannaerImageUrl;
                aspService.get_ecommerce_info().then(res => {
                    resolve(true);
                });
                // check new posData update..
                getPosData(posid).then(response=>{
                    let newPosData = response;
                    newPosData.POSID = posid;
                    if(localPosData !== JSON.stringify(newPosData)){
                        localStorage.setItem(posid+'_POSDATA',JSON.stringify(newPosData));
                        alert('update')
                        window.location.reload();
                    } else {
                    }
                });
            } else {
                getPosData(posid).then(response=>{
                    posData = response;
                    posData.POSID = posid;
                    window.logo = response.BannaerImageUrl;
                    localStorage.setItem(posid+'_POSDATA',JSON.stringify(posData));
                    aspService.get_ecommerce_info().then(res => {
                        resolve(true);
                    });
                });
            }
            
        });
    })
}




export function getCode() {
    prevTable = cookie.get('tableNo');
    prevOrder = cookie.get('orderid');
    return new Promise((resolve, reject) => {
        let app_code = window.location.pathname.substr(1).split('/')[0];
        if (app_code !== '') {
            window.app_code = app_code;
            addThemeCss();
            getTable().then(tableStatus=>{
                if(tableStatus){
                    // make start----
                    makeStart().then(()=>{
                        checkPayLink().then(payLinkStatus=>{
                            checkFeedbackLink().then(LinkStatus=>{
                                resolve(true);
                            });
                        });
                        
                    }); // start normal app ---
                } else {
                    makeStart().then(()=>{
                        checkPayLink().then(payLinkStatus=>{
                            if(payLinkStatus){
                                // make start ---
                                resolve(true) // start payment link page ----                    
                            } else {
                                checkFeedbackLink().then(LinkStatus=>{
                                    if(LinkStatus){
                                        // make start ---
                                        resolve(true) // start payment link page ----                    
                                    } else {
                                        resolve(false); // start scan page ---
                                    }
                                });
                            }
                        });
                    })
                }
            });
        } else {
            resolve(false); // app-code not found ----
        }
    });
}