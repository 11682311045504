import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
import Topbar from '../topbar/topbar';
import * as ecomService from '../../services/ecomService';
import * as cartService from '../../services/cartService';
import './css/product.css';

class Product extends Component {

    state = {
        product: null,
        price: 0,
        varients: []
    }
    constructor() {
        super();
        this.updateVarient = this.updateVarient.bind(this);
        this.addToCart = this.addToCart.bind(this);
    }


    productDetails() {
        if (this.state.product != null) {
            let product = this.state.product;
            return <div>
                <div className="product-image" style={{ backgroundImage: "url(" + product.default_image + ")" }}></div>
                {product.Productname}
                {ReactHtmlParser(product.description)}
                {this.price()}
            </div>
        } else {
            return '';
        }
    }
    varientOptions(varient) {
        varient.options = varient.options.filter(op => op.id !== 0)
        return <div className="options">
            {varient.options.map(option => (
                <div className="option" key={option.id}>
                    {(varient.is_multiple == true) ? <input type="checkbox" onChange={(e) => this.updateVarient(e, option)} /> : <input type="radio" onChange={(e) => this.updateVarient(e, option)} />}
                    <label>{option.title} ({option.price})</label>
                </div>
            ))}
        </div>
    }

    productAddons() {
        let product = this.state.product;
        if (product != null) {
            return <div>
                {
                    product.ProductVarients.map(varient => (
                        <div key={varient.id}>
                            <h3>{varient.name}</h3>
                            <hr />
                            {this.varientOptions(varient)}
                        </div>
                    ))
                }
            </div>
        } else {
            return '';
        }
    }

    updateVarient = (e, option) => {
        let el = e.target;
        let newVarients = this.state.varients;

        if (newVarients.indexOf(option.id) != -1) {
            newVarients.splice(newVarients.indexOf(option.id), 1);
            if (option.pricetype === '+') {
                this.setState({ price: this.state.price - parseFloat(option.price) });
            } else {
                this.setState({ price: this.state.price + parseFloat(option.price) });
            }

        }
        if (el.checked) {
            newVarients.push(option.id);
            if (option.pricetype === '+') {
                this.setState({ price: this.state.price + parseFloat(option.price) });
            } else {
                this.setState({ price: this.state.price - parseFloat(option.price) });
            }
        }
        this.setState({ varients: newVarients })
    }

    price() {
        return this.state.price;
    }

    render() {
        return (
            <div>
                <Topbar />
                {this.productDetails()}
                {this.productAddons()}


                <button type="button" onClick={this.addToCart}>Add to cart</button>
            </div>
        );
    };
    addToCart = (e) => {
        let _varients = [];
        this.state.varients.forEach((id) => {
            _varients.push({ id: id, quantity: 1 })
        })
        cartService.addToCart(this.state.product.id, _varients, this.state.count + 1, '').then((cartItems) => {
            //this.updateCount(cartItems);
        });
    }

    getProduct() {
        let _this = this;
        ecomService.call('Product/getProduct', 'get', {}, { "access_token": "", "ProductID": _this.props.match.params.id }, {}).then((res) => {
            console.log(res.data.ProductDetails)
            _this.setState({ product: res.data.ProductDetails });
            _this.setState({ price: res.data.ProductDetails.price });
        });

    }

    componentDidMount() {
        this.getProduct();
    }

}

export default Product;