export var a = '10';

var _Observers = [];
var hides = [];

export function show(data) {
    _Observers.forEach((func) => {
        func(data);
    });
}

export function hide() {
    hides.forEach((func) => {
        func();
    });
}

export function registered(func, close) {
    _Observers.push(func);
    hides.push(close);
}