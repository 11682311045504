import React, { Component } from 'react';
import QrReader from 'react-qr-scanner';
import * as cookie from '../../cookie';
import * as posService from '../../services/posService';
import FlipCameraIosIcon from '@material-ui/icons/FlipCameraIos';
import './css/scan.css';


class Scan extends Component {
	constructor(props) {
		super(props)
		this.state = {
			delay: 100,
			result: 'No result',
			face: 'front',
			logo: '',
			scan:'scaning...'
		}
		this.handleScan = this.handleScan.bind(this);
		this.changeCamera = this.changeCamera.bind(this);
	}

	loadScript(id,src){
		return new Promise((resolve,reject)=>{
			let script = document.getElementById(id);
			if(!script){
				let _script = document.createElement('script');
				_script.id = id;
				_script.src = src;


				window.JsQRScannerReady = ()=>{
					resolve();
				}
				document.body.appendChild(_script);
			} else {
				resolve();
			}
		})
	}

	provideVideoQQ()   {
        return navigator.mediaDevices.enumerateDevices()
        .then(function(devices) {
            var exCameras = [];
            devices.forEach(function(device) {
            if (device.kind === 'videoinput') {
              exCameras.push(device.deviceId)
            }
         });
            
            return Promise.resolve(exCameras);
        }).then(function(ids){
            if(ids.length === 0)
            {
              return Promise.reject('Could not find a webcam');
            }
            
            return navigator.mediaDevices.getUserMedia({
                video: {
                  'optional': [{
                    'sourceId': ids.length === 1 ? ids[0] : ids[1]//this way QQ browser opens the rear camera
                    }]
                }
            });        
        });                
    } 



	stopCamera(videoElem) {
		const stream = videoElem.srcObject;
		if(stream !== null){
			const tracks = stream.getTracks();
	  
			tracks.forEach(function(track) {
			track.stop();
			});
		}
		
	  
		videoElem.srcObject = null;
	}

	handleScan = data => {
		this.setState({scan:data})
		if (data) {
			this.setState({scan:data})
			try {
				let _url = new URL(data);
				let _tdata = decodeURIComponent((new RegExp("[?|&]" + "tableno" + "=([^&;]+?)(&|#|;|$)").exec(_url.search) || [, ""])[1].replace(/\+/g, "%20")) || null
				if (_tdata != null) {
					_tdata = atob(_tdata);
					if (_tdata.search('dts_') === 0) {
						let tableno = parseInt(_tdata.replace('dts_', ''));
						cookie.set('tableNo', tableno);
						window.jbScanner.stopScanning();
						window.jbScanner.removeFrom(document.getElementById("scanner"));
						//this.stopCamera(document.getElementsByTagName('video')[0]);
						setTimeout(()=>{window.location.href = data;},600);
						
					}
				}
			} catch (error) {
				//this.setState({scan:data})
			}
		}
	}

	handleError = err => {
		console.error(err)
	}

	changeCamera = e => {
		this.stopCamera(document.getElementsByTagName('video')[0]);
		switch (this.state.face) {
			case 'front':
				this.setState({ face: 'rear' })
				break;
			case 'rear':
				this.setState({ face: 'front' })
				break;
				default :

		}
	}

	render() {
		/* const previewStyle = {
			height: '60vh',
			width: '100%',
		} */
		/* 
		<QrReader
						ref="qrReader"
						delay={this.state.delay}
						style={previewStyle}
						onError={this.handleError}
						onScan={this.handleScan}
						facingMode={this.state.face}
					/>
					<button onClick={this.changeCamera}><FlipCameraIosIcon /></button>
		*/
		return (
			<div className="scan">
				<div className="header">
					<div className="icon-btn" ><svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24"><path fill="#ffffff" d="M15,21h-2v-2h2V21z M13,14h-2v5h2V14z M21,12h-2v4h2V12z M19,10h-2v2h2V10z M7,12H5v2h2V12z M5,10H3v2h2V10z M12,5h2V3h-2V5 z M4.5,4.5v3h3v-3H4.5z M9,9H3V3h6V9z M4.5,16.5v3h3v-3H4.5z M9,21H3v-6h6V21z M16.5,4.5v3h3v-3H16.5z M21,9h-6V3h6V9z M19,19v-3 l-4,0v2h2v3h4v-2H19z M17,12l-4,0v2h4V12z M13,10H7v2h2v2h2v-2h2V10z M14,9V7h-2V5h-2v4L14,9z M6.75,5.25h-1.5v1.5h1.5V5.25z M6.75,17.25h-1.5v1.5h1.5V17.25z M18.75,5.25h-1.5v1.5h1.5V5.25z" /></svg></div>
					<h1>Scan a table QR</h1>
				</div>
				<div className="camera">
					<div id="scanner"></div>
				</div>
				<div className="footer">

				</div>
			</div>
		);
	};

	componentDidMount() {
		console.log('Scan Page....');
		this.loadScript('scannerJS','/scanner/jsqrscanner.nocache.js').then(()=>{
			window.jbScanner = new window.JsQRScanner(this.handleScan);
			window.jbScanner.setSnapImageMaxSize(300);
			let scannerParentElement = document.getElementById("scanner");
			if (scannerParentElement) {
				window.jbScanner.appendTo(scannerParentElement);
			}
		});

		





		
		/* setTimeout(() => { this.setState({ face: 'rear' }) }, 100);
		this.setState({ logo: posService.posData.BannaerImageUrl }) */
	}

}

export default Scan;