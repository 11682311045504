import React, { Component } from 'react';
import * as cartService from '../../services/cartService';
import './css/addToCart.css';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';

import Button from '@material-ui/core/Button';
import {withStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const DialogButton = withStyles((theme) => ({
	root: {
	  color: theme.palette.getContrastText(window.theme['primary-600']),
	  backgroundColor: window.theme['primary-600'],
	  fontFamily:'inherit',
	  '&:hover': {
		backgroundColor: window.theme['primary-400'],
	  },
	  '&:disabled': {
		backgroundColor: '#d4d4d4',
	  },
	},
}))(Button);


class AddToCart extends Component {
    state = {
        count: 0,
        product: null,
        working:false,
        removeDialog:false
    };

    constructor() {
        super();
        this.handleIncrement = this.handleIncrement.bind(this);
        this.handleDecrement = this.handleDecrement.bind(this);
        this.handleYes = this.handleYes.bind(this);
        this.handleNo = this.handleNo.bind(this);
    }

    getIdentity() {
        let _this = this;
        let identities = [];
        cartService.ecomCart.forEach(item => {
            if (_this.props.id == item.id) {
                identities.push(item);
                //identity = item.cartProductIdentifier
            }
        });


        let identity = this.props.identity;
        if (identity != undefined) {
            let res = null;
            identities.forEach((item) => {
                if (item.cartProductIdentifier == identity) {
                    res = [item];
                }
            });
            if (res != null) {
                return res;
            } else {
                return identities;
            }
        } else {
            return identities;
        }
    }

    handleIncrement(e) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({working:true})
        let _this = this;
        let identity = this.props.identity;
        if (identity === undefined) {
            let identities = this.getIdentity();
            let product = this.state.product;
            let isVarientRequired = false;
            if (product.ProductVarients.length > 0) {
                isVarientRequired = true
            }
         
            if (isVarientRequired) {
                console.warn('Product varient Required...');
                this.setState({working:false})
                this.props.customize(this.state.product);
                return;
            }


            if (identities.length === 0) {
                cartService.addToCart(this.props.id, [], 1, '[""]').then((cartItems) => {
                    _this.updateCount();
                });
            } else if (identities.length > 1) {
                this.setState({working:false})
                this.props.customize(this.state.product);
                return;
                // ask for which item .. popup
            } else {
                console.log('hmmm')
                let addedProduct = identities[0];
                let count = addedProduct.cartQuantity + 1;
                let note = JSON.parse(addedProduct.specialInstruction);
                note.push(note[0]);
                let varients = (addedProduct.varients === undefined) ? [] : addedProduct.varients;
                cartService.addToCart(addedProduct.id, varients, count, JSON.stringify(note)).then((cartItems) => {
                    _this.updateCount();
                });
            }
        } else {
            let cartProduct = this.props.cartProduct;
            let count = cartProduct.cartQuantity + 1;
            let varients = (cartProduct.varients === undefined) ? [] : cartProduct.varients;
            let note = JSON.parse(cartProduct.specialInstruction);
            note.push(note[0]);
            cartService.addToCart(cartProduct.id, varients, count, JSON.stringify(note)).then((cartItems) => {
                _this.updateCount();
            });
        }
    }

    handleDecrement(e) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({working:true})
        let _this = this;
        let identity = this.props.identity;
        if (identity === undefined) {
            let identities = this.getIdentity();
            if (identities.length > 1) {
                this.setState({working:false})
                // ask for which item .. popup
                this.setState({removeDialog:true});
            } else {
                let addedProduct = identities[0];
                let count = addedProduct.cartQuantity - 1;
                let note = JSON.parse(addedProduct.specialInstruction);
                note.pop();
                let varients = (addedProduct.varients === undefined) ? [] : addedProduct.varients;
                if (count == 0) {
                    cartService.removeFromCart(addedProduct.id, addedProduct.cartProductIdentifier).then((cartItems) => {
                        this.updateCount();
                    });
                } else {
                    cartService.addToCart(addedProduct.id, varients, count, JSON.stringify(note)).then((cartItems) => {
                        _this.updateCount();
                    });
                }

            }
        } else {
            let cartProduct = this.props.cartProduct;
            let count = cartProduct.cartQuantity - 1;
            let varients = (cartProduct.varients === undefined) ? [] : cartProduct.varients;
            let note = JSON.parse(cartProduct.specialInstruction);
            note.pop();
            if (count == 0) {
                cartService.removeFromCart(cartProduct.id, cartProduct.cartProductIdentifier).then((cartItems) => {
                    this.updateCount();
                });
            } else {
                cartService.addToCart(cartProduct.id, varients, count, JSON.stringify(note)).then((cartItems) => {
                    _this.updateCount();
                });
            }

        }
    }

    statasClass(){
        let cls = ['add-to-cart'];
        if(this.state.working){
            cls.push('working')
        }

        return cls.join(' ');
    }


    handleYes(e){
        e.preventDefault();
        e.stopPropagation();
        this.setState({removeDialog:false});
        this.props.history.push(`/${window.app_code}/cart`);
    }
    handleNo(e){
        e.preventDefault();
        e.stopPropagation();
        this.setState({removeDialog:false});
    }

    render() {
        return (
            <div className={this.statasClass()} data-count={this.state.count}>
                <div className={"decrease " + this.getClass()} onClick={this.handleDecrement}><RemoveIcon/></div>
                <div className="_count" onClick={this.handleIncrement}>{this.formatCount()}</div>
                <div className={"increase " + this.getClass()} onClick={this.handleIncrement}><AddIcon /></div>

                <Dialog
                    open={this.state.removeDialog}
                    TransitionComponent={Transition}
                    keepMounted
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title">{"Remove Item From Cart"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            This item has multiple customization options, to remove this item proceed to cart?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <DialogButton onClick={this.handleNo} >No</DialogButton>
                        <DialogButton onClick={this.handleYes} >Yes</DialogButton>
                    </DialogActions>
                </Dialog>

            </div>

            
        );
    }

    getClass() {
        let { count } = this.state;
        return count === 0 ? 'hide' : '';
    }

    formatCount() {
        let { count } = this.state;
        return count === 0 ? 'ADD' : count;
    }

    updateCount() {
        this.setState({working:false});
        let update = false;
        if (this.props.identity != undefined) {
            cartService.CART.forEach(item => {
                if (this.props.identity == item.cartProductIdentifier && !item.previous) {
                    update = true;
                    this.setState({ count: item.cartQuantity });
                }
            });
        } else {
            let count = 0;
            cartService.CART.forEach(item => {
                if (this.props.id == item.id && !item.previous) {
                    update = true;
                    count += parseInt(item.cartQuantity);

                }
            });
            this.setState({ count: count });
        }

        if (!update) {
            this.setState({ count: 0 });
        }
    }


    componentDidMount() {
        this.setState({ product: this.props.product });
        cartService.getCart().then((cartItems) => {
            this.updateCount();
        });
        cartService.Observe(() => {
            cartService.getCart().then((cartItems) => {
                this.updateCount();
            });
        });
    }

}

export default AddToCart;