import React, { Component } from 'react';
import Topbar from '../topbar/topbar';
import AddToCart from '../addToCart/addToCart';
import * as posService from '../../services/posService';
import * as cartService from '../../services/cartService';
import * as cookie from '../../cookie';
import './css/cart.css';
import Button from '@material-ui/core/Button';
import {withStyles} from '@material-ui/core/styles';
import ReactHtmlParser from 'react-html-parser';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';


const PrimaryButton = withStyles((theme) => ({
	root: {
	  color: theme.palette.getContrastText(window.theme['primary-400']),
	  backgroundColor: window.theme['primary-400'],
	  fontFamily:'inherit',
      fontSize:'16px',
	  '&:hover': {
		backgroundColor: window.theme['primary-400'],
	  },
	  '&:disabled': {
		backgroundColor: '#d4d4d4',
	  },
	},
}))(Button);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DialogButton = withStyles((theme) => ({
	root: {
	  color: theme.palette.getContrastText(window.theme['primary-600']),
	  backgroundColor: window.theme['primary-600'],
	  fontFamily:'inherit',
	  whiteSpace:'nowrap',
	  '&:hover': {
		backgroundColor: window.theme['primary-400'],
	  },
	  '&:disabled': {
		backgroundColor: '#d4d4d4',
	  },
	},
}))(Button);



class Cart extends Component {
    state = {
        cart: [],
        priceInfo: {},
        isEditNote: '',
        editProduct: null,
        noteDialog:false,
        currentNote:'',
    }

    constructor() {
        super();
        this.placeOrder = this.placeOrder.bind(this);
        this.listCount = 0;
    }

    getCounter(product) {
        if (product.previous) {
            return <div className="count"><span>X</span> {product.cartQuantity}</div>;
        } else {
            return <div className="counter">
                <AddToCart key={product.id} id={product.id} identity={product.cartProductIdentifier} cartProduct={product}></AddToCart>
            </div>
        }
    }

    editNote(product) {
        (document.getElementById('editNote')).value = product.specialNote;
        this.setState({ isEditNote: '', editProduct: product,noteDialog:true ,currentNote:product.specialNote });
    }

    editNotePopup() {
        if (this.state.editProduct === null) {
            return '';
        } else {
            return (
                <div >
                    <div className="body-inside">
                        <textarea name="editNoteBack" id="editNoteBack" cols="30" rows="10" defaultValue={this.state.editProduct.specialNote}></textarea>
                    </div>

                    <div className="actions">
                        <PrimaryButton onClick={(e) => { this.setState({ isEditNote: '', editProduct: null }) }}>Cancel</PrimaryButton>
                        <PrimaryButton onClick={(e) => { this.updateNote() }}>Update</PrimaryButton>
                    </div>
                </div>
            )
        }
    }
    updateNote = (e) => {
        let note = (document.getElementById('editNote')).value;
        console.log(note);
        let product = this.state.editProduct;
        let currentQuantity = product.cartQuantity;
        let instruction = product.specialNote;
        if (cartService.posCart[product.cartProductIdentifier] !== undefined) {
            product.cartQuantity += parseInt(cartService.posCart[product.cartProductIdentifier].Quantity);
        }

        var cdata = { 'cartItems': [] };
        var cartitem = {};
        function _addAddon(id) {
            if (cartitem.varients === undefined) {
                cartitem['varients'] = [];
            }
            cartitem.varients.push({ id: id, quantity: 1 })
        }

        cartitem['product_id'] = product.id;
        if (product.specialInstruction === '' || product.specialInstruction === '[]') {
            product.specialInstruction = '[""]';
        }
        var instructions = JSON.parse(product.specialInstruction);
        var rem = product.cartQuantity - instructions.length;
        for (var i = 0; i < rem; i++) {
            instructions.push("");
        }
        var _instructions = instructions.map(function (v) {
            if (currentQuantity > 0) {
                if (v === instruction) { v = note; currentQuantity--; }
            }
            return v;
        });


        cartitem['specialInstruction'] = JSON.stringify(_instructions)
        cartitem['quantity'] = product.cartQuantity;
        if (product.varients !== undefined) {
            var vids = [];
            product.varients.forEach(v => {
                vids.push(v.id)
            });
            vids.sort();
            vids.forEach(vid => {
                _addAddon(vid)
            })

        }
        cdata.cartItems.push(cartitem);
        console.log(cdata);
        cartService.addToCart(product.id, cartitem.varients, cartitem.quantity, cartitem.specialInstruction).then(() => {
            this.setState({ isEditNote: '', editProduct: null, noteDialog:false });
        });
        /* addToCart(cdata,function(cres){
            if($('[data-widget="cart"]').length > 0){
                apiHandler.loadAPI($('[data-widget="cart"]'));
            } else {
                $.each($('[data-smart-counter]'),function(i,el){cartCounter.init($(el))})
            }
            window.top.postMessage('frameNonFixed','https://casinoeatz.com');
        });

        $('#dynamicBSM').modal('close');
        
        */
    }


    

    getListCount() {
        this.listCount++;
        return this.listCount;
    }

    placeOrder() {
        let products = [];
        let generalProduct = false;

        this.state.cart.forEach(item => {
            if (!item.redeemableProduct) { generalProduct = true }
            if (!item.previous) {
                var Addons = [];
                var comments = [];
                products.push({
                    "ProductCode": item.sku,
                    "Quantity": item.cartQuantity,
                    "Cart_Identifier": item.cartProductIdentifier,
                    "Comments": comments.join(','),
                    "Comments_Json": '',//item.specialInstruction,
                    "Addons": Addons,
                    "IsRedeemItem": item.redeemableProduct
                });
            }
        });

        if (!generalProduct) {
            alert('no general product');
            return;
        }
        posService.call('SaveDiningOrderWithAddOn', 'post', {}, {}, {
            "req":
            {
                "APIToken": posService.posData.Code,
                "POsID": posService.posData.POSID,
                "TableID": cookie.get('tableNo'),
                "CustomerID": cookie.get('customerid'),
                "child": products
            }
        }).then((res) => {
            //isServed = false;
            //cookie.set('isServed',false,0.1666);
            //chk_req = false;
            //cookie.set('chk_req',false,0.1666);
            //handleCartButtons();
            console.log(res);
            /* var data = {
                for:'order',
                orderid:res.Result.POSOrderID
            } */
            /* if(getURLParameter('pay') == null &&  getURLParameter('review') ==null){
                localStorage.setItem(BUID+'_POSOrderID',res.Result.POSOrderID);
            } */


            //var redirectURL =  '/order?for=' + btoa(JSON.stringify(data));
            this.props.history.push(`/${window.app_code}/order/` + res.Result.POSOrderID);
        }).catch(function () {
            //$('[id="dtsPlaceOrder"]').removeClass('disabled');
        });



        /* if(paymentOnProcess){
            popup.dialog('WARNING!', `<p style="text-align:center; font-size:20px; color:#222;">Payment on process. Please wait.</p>`,
                { 
                    place:'center',
                    btns:{
                        "Ok":{
                            class:'bg-grey-800 text-grey-50 round remove-shadow default bold muCancel',
                            action:function(){
                                popup.removeDialog();
                            }
                        },
                       
                    }
                }, 'thm2');
    
            return;
        } */

    }

    price(amount) {
        return window.currencySymbols[window.manifest.Currency] + ' ' + parseFloat(amount).toFixed(2)
    }

    getProductAddons(addons) {
        if (addons === '') {
            return '';
        } else {
            return <p className="addons">{ReactHtmlParser(addons)}</p>
        }
    }
    getItemClass(product){
        let cls = ["cart-item"];//+ ()?'':''
        if(product.IsDeleted === '1'){cls.push('deleted')};
        return cls.join(' ');
    }

    getNote(product) {
        if (product.previous) {
            if (product.specialNote === '') {
                return '';
            } else {
                return (
                    <div className="noteHolder">
                        <p className="note">{product.specialNote}</p>
                    </div>
                )
            }
        } else {
            if (product.specialNote === '') {
                return (
                    <div className="noteHolder">
                        <PrimaryButton className="btn-sm" onClick={(e) => { this.editNote(product) }}>Add Note</PrimaryButton>
                    </div>
                );
            } else {
                return (
                    <div className="noteHolder">
                        <p className="note">{product.specialNote}</p>
                        <PrimaryButton className="btn-sm" onClick={(e) => { this.editNote(product) }}>Edit Note</PrimaryButton>
                    </div>

                )
            }
        }
    }

    getCartItems() {
        if (this.state.cart.length > 0) {
            return <div className="cart-items">
                {this.state.cart.map(product => (
                    <div key={this.getListCount()} className={this.getItemClass(product) }>
                        <div className="prod">
                            <p className="product-name">{product.Productname}</p>
                            {this.getProductAddons(product.addons)}
                            {this.getNote(product)}
                        </div>

                        <div className="right">
                            {this.getCounter(product)}
                            <p className="price">{this.price(product.price)}</p>
                        </div>
                        <p className="prod-label deleted">Cancelled</p>
                    </div>
                ))}
            </div>
        } else {
            return <p className="no-cart-items">Your cart is empty.</p>
        }
    }

    getCartBill() {
        if (this.state.cart.length > 0) {
            return <div>
                <h2>BILL DETAILS</h2>
                <div className="bill-details">
                    <div className="bill-row">
                        <label>Item Total</label>
                        <div className="price">{this.price(this.state.priceInfo.subtotal)}</div>
                    </div>
                    <div className="bill-row">
                        <label>Tax</label>
                        <div className="price">{this.price(this.state.priceInfo.Taxes)}</div>
                    </div>
                    <div className="bill-row total">
                        <label>Total</label>
                        <div className="price">{this.price(this.state.priceInfo.Gross)}</div>
                    </div>
                </div>
            </div>
        } else {
            return null
        }
    }
    getNoteText(){
        if(this.state.editProduct != null){
            return this.state.editProduct.specialNote;
        } else {
            return ''
        }
    }

    render() {
        return (
            <div className="cart">
                <Topbar {...this.props}/>
                {this.getCartItems()}
                {this.getCartBill()}

                <div className={"edit-note " + this.state.isEditNote}>
                    <div className="body">{this.editNotePopup()}</div>
                </div>


                <Dialog
                    open={this.state.noteDialog}
                    TransitionComponent={Transition}
                    keepMounted
                    aria-labelledby="dialog-2-title"
                    aria-describedby="dialog-2-description"
                >
                    <DialogTitle id="dialog-2-title">{"NOTE"}</DialogTitle>
                    <DialogContent>
                        <textarea className="item-note" id="editNote" placeholder="Item Note" defaultValue={this.state.currentNote}></textarea>
                    </DialogContent>
                    <DialogActions>
                        <DialogButton onClick={()=>{this.setState({noteDialog:false})}} >Cancel</DialogButton>
                        <DialogButton onClick={this.updateNote} >Update</DialogButton>
                    </DialogActions>
                </Dialog>
            </div>

        );
    }



    componentDidMount() {
        cartService.Observe(() => {
            cartService.getCart().then(cart => {
                this.setState({ cart: cart, priceInfo: cartService.priceInfo });
            });
        });
        cartService.getCart().then(cart => {
            this.setState({ cart: cart, priceInfo: cartService.priceInfo });
        });
    }
}

export default Cart;