import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Topbar from '../topbar/topbar';
import {  withStyles  } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import './css/order.css';


const PrimaryButton = withStyles((theme) => ({
	root: {
	  color: theme.palette.getContrastText(window.theme['primary-400']),
      backgroundColor: window.theme['primary-400'],
      fontFamily:'inherit',
	  '&:hover': {
		backgroundColor: window.theme['primary-400'],
	  },
	  '&:disabled': {
		backgroundColor: '#d4d4d4',
	  },
	},
  }))(Button);

class Order extends Component {
    state = {

    }

    render() {
        return (
            <div>
                <Topbar {...this.props}/>
                <div className="order-placed">
                    
                    <div className="block">
                      <img src={window.logo} alt="logo" className="logo-image" />

                      <fieldset>
                        <legend>Order No</legend>
                        <h3>{this.props.match.params.id}</h3>
                      </fieldset>
                      
                      <Link to={`/${window.app_code}/products`}>
                        <PrimaryButton className="btn-center">Continue Order</PrimaryButton>
                      </Link>
                    </div>
                    
                    
                </div>
            </div>

        );
    }

}

export default Order;