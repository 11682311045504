import React, { Component } from 'react';
import './css/loader.css';
class Loader extends Component {
    state = {
        active:this.props.active,
        class:this.props.className
    }
    getClass(){
        let cls = ['loader-container'];
        cls.push(this.state.class)
        if(this.state.active){
            cls.push('active')
        }
        return cls.join(' ');
    }
    render() {
        return (
            <div className={this.getClass()}>
                <div className="loader"></div>
            </div>
        );
    }
    componentDidMount() {
        //console.log(this.props)
        //console.log(this.props.match.params.appcode)
    }
    componentWillReceiveProps(nextProps) {
        // You don't have to do this check first, but it can help prevent an unneeded render
        this.setState({
            active:nextProps.active,
            class:nextProps.className
        });
        //console.log('---------',nextProps.active);
        /* if (nextProps.startTime !== this.state.startTime) {
          this.setState({ startTime: nextProps.startTime });
        }
        console.log(nextProps,this.props) */
      }
      
}

export default Loader;