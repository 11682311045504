export function register() { // Register the service worker
    if ('serviceWorker' in navigator) { //process.env.NODE_ENV === 'production' && 
        window.addEventListener('load', () => {
            const swUrl = '/service-worker.js';
            navigator.serviceWorker
                .register(swUrl)
                .then(registration => {
                    registration.onupdatefound = () => {
                        const installingWorker = registration.installing;
                        installingWorker.onstatechange = () => {
                            if (installingWorker.state === 'installed') {
                                if (navigator.serviceWorker.controller) {
                                    // At this point, the old content will have been purged and
                                    // the fresh content will have been added to the cache.
                                    // It's the perfect time to display a "New content is
                                    // available; please refresh." message in your web app.
                                    console.log('New content is available; please refresh.');
                                } else {
                                    // At this point, everything has been precached.
                                    // It's the perfect time to display a
                                    // "Content is cached for offline use." message.
                                    console.log('Content is cached for offline use.');
                                }
                            }
                        };
                    };
                })
                .catch(error => {
                    console.error('Error during service worker registration:', error);
                });
        });
    }
}

export function unregister() {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready.then(registration => {
            caches.keys().then(function(cacheNames) {
                cacheNames.forEach(function(cacheName) {
                    caches.delete(cacheName);
                });
            });
            registration.unregister();
            /* const registrations = navigator.serviceWorker.getRegistrations();
            const unregisterPromises = registrations.map(registration => registration.unregister()); */

            /* const allCaches = caches.keys();
            const cacheDeletionPromises = allCaches.map(cache => caches.delete(cache));

            Promise.all(cacheDeletionPromises); */
        });


    }
}