import * as cookie from '../cookie';


//var BUID = window.BUID;
var CACHE = {};
var apiDomain = process.env.REACT_APP_EMOM_DOMAIN + "/webservice/";

function makeQueryString(urldata) {
    return Object.keys(urldata).map(function(key) {
        return encodeURIComponent(key) + '=' + encodeURIComponent(urldata[key]);
    }).join('&');
}

function resetToken() {
    console.log('RE-SET- Token')
    return new Promise((resolve, reject) => {
        cookie.del(window.BUID + "_access_token");
        checkToken().then(() => { 
            console.log('CHAIN')
            resolve() 
        });
    });
}

export function getUserToken(){
    return btoa([
        cookie.get('tableNo'),
        cookie.get('customerid'),
        cookie.get('customerCountryCode'),
        cookie.get('customerMobile'),
        cookie.get('customerName')
    ].join('|'));
}


function checkToken() {
    return new Promise((resolve, reject) => {
        let access_token = cookie.get(window.BUID + "_access_token");
        if (access_token == null || access_token === 'undefined') {
            let up = btoa(window.ECOMMERCE.UserName + ':' + window.ECOMMERCE.Password);
            let settings = {
                "async": true,
                "cors": true,
                "method": "POST",
                "headers": {
                    "content-type": "application/json",
                    "authorization": "Basic " + up
                },
                "body": JSON.stringify({
                     "grant_type": "client_credentials",
                     "client_id":window.ECOMMERCE.UserName,
                     "client_secret":window.ECOMMERCE.Password
                     })
            }

            fetch(apiDomain + "Authenticate", settings)
                .then(res => res.json())
                .then((response) => {
                    let date = new Date();
                    date.setTime(date.getTime() + (3 * 60 * 60 * 1000));
                    let exp = "expires=" + date.toUTCString();
                    cookie.set(window.BUID + "_access_token", response.access_token, 1, exp);
                    localStorage.setItem(window.BUID + "_access_token", response.access_token);
                    cookie.set(window.BUID + "_access_token", response.access_token, 1, exp);
                    resolve();
                });
        } else {
            resolve();
        }
    });
}

export function call(api, method, headers, urldata, data) {
    return new Promise((resolve, reject) => {
        let url = apiDomain + api;
        let settings = {
            headers: {},
            "method": method,
            "async": true,
            "mode": 'cors',
        }

        settings.headers = Object.assign(settings.headers, headers);
        checkToken().then(() => {
            let access_token = cookie.get(window.BUID + "_access_token");
            if (urldata.access_token !== undefined) {
                urldata.access_token = access_token;
            }
            if (data.access_token !== undefined) {
                data.access_token = access_token;
            }
            if (method.toLowerCase() === 'post') {
                settings.cache = 'no-cache';
                settings.body = JSON.stringify(data);
            } else {
                urldata = Object.assign(urldata, data);

            }
            url += '?' + makeQueryString(urldata);
            fetch(url, settings)
                .then(res => res.json())
                .then((response) => {
                    if (response.error && response.msg.search('Authentication Token Expired') === 0) {
                        //alert('Authentication Token Expired')
                        resetToken().then(()=> {
                            call(api, method, headers, urldata, data).then((_res) => { resolve(_res) })
                        })
                    } else {
                        resolve(response)
                    }
                })
        })

    })
}

function findCache(api,data){
    return new Promise((resolve,reject)=>{
        let _cache = false;
        if(CACHE[api] !== undefined ){
            CACHE[api].queries.forEach(query=>{
                if(JSON.stringify(query.data) === JSON.stringify(data)){
                    _cache =true;
                    resolve(query.response);
                }
            })
        }
        if(!_cache){
            reject(false);
        }
    });
}

function setCache(api,data,response){
    if(CACHE[api] === undefined){
        CACHE[api] = {};
    }
    if(CACHE[api].queries === undefined){
        CACHE[api].queries = [];
    }
    CACHE[api].queries.push({data:data,response:response});
}


export function getProducts(data){
    return new Promise((resolve,reject)=>{
        findCache('getAllProducts',data).then(response=>{
            resolve(response);
        }).catch(()=>{
            call('Product/getAllProducts', 'get', {}, JSON.parse(JSON.stringify(data)), {}).then((response) => {
                setCache('getAllProducts',data,response);
                resolve(response);
            }).catch(reject);
        });       
    });
}

export function getCategories(data) {
    return new Promise((resolve,reject)=>{
        findCache('getCategories',data).then(response=>{
            resolve(response);
        }).catch(()=>{
            call('Category/getCategories', 'get', {}, JSON.parse(JSON.stringify(data)), {}).then((res) => {
                setCache('getCategories',data,res);
                resolve(res);
            }).catch(reject);
        })        
    });
}


export function getCategoriesTree(data) {
    return new Promise((resolve,reject)=>{
        findCache('getCategoriesTree',data).then(response=>{
            resolve(response);
        }).catch(()=>{
            call('Category/getCategoriesTree', 'get', {}, JSON.parse(JSON.stringify(data)), {}).then((res) => {
                setCache('getCategoriesTree',data,res);
                resolve(res);
            }).catch(reject);
        })        
    });
}

export function fn2() {
    return 'fn2'
}