var apiDomain = process.env.REACT_APP_ASP_DOMAIN + '/webapi/';
var Token = null;

export function getToken() {
    return new Promise((resolve, reject) => {
        if (Token !== null) {
            resolve(Token)
        } else {
            fetch(apiDomain + 'Authenticate/login', {
                    headers: {
                        "authorization": "Bearer apiuser:wXPocq0fG24="
                    },
                    "method": 'post',
                    "async": true,
                    "mode": 'cors',
                }).then(response => {
                    Token = response.headers.get('Token');
                    return response.json()
                }).then((res) => {
                    resolve(Token)
                })
                .catch((error) => {
                    console.log('Looks like there was a problem: \n', error);
                });
        }
    })
}


export function get_manifest(app_code) {
    return new Promise((resolve, reject) => {
        if (window.manifest != undefined) {
            window.BUID = parseInt(window.getURLParameter('buid', window.manifest.ids));
            resolve(window.manifest);
        } else {
            getToken().then((_token) => {
                fetch(apiDomain + 'manifest.json?code=' + app_code, {
                    headers: { "Token": _token },
                }).then((response) => {
                    return response.json()
                }).then((data) => {
                    window.manifest = data;
                    window.BUID = parseInt(window.getURLParameter('buid', data.ids))
                    resolve(data);
                }).catch(function(error) {
                    console.log('Looks like there was a problem: \n', error);
                    reject(error);
                });
            })
        }

    })
}

export function get_ecommerce_info() {
    return new Promise((resolve, reject) => {
        if (localStorage.getItem(window.BUID + '_ECOMMERCE') != null) {
            window.ECOMMERCE = JSON.parse(localStorage.getItem(window.BUID + '_ECOMMERCE'));
            resolve(window.ECOMMERCE);
        } else {
            getToken().then((_token) => {
                let formData = new FormData();
                formData.append('BUID', window.BUID);
                fetch(apiDomain + 'BusinessUnit/GetECommerceComponentInfo', {
                    headers: {
                        "Token": _token,
                        "content-type": "application/json",
                    },
                    "method": 'POST',
                    "async": true,
                    "mode": 'cors',
                    "body": JSON.stringify({ BUID: window.BUID })
                }).then((response) => {
                    return response.json()
                }).then((data) => {
                    localStorage.setItem(window.BUID + '_ECOMMERCE', JSON.stringify(data));
                    window.ECOMMERCE = data;
                    resolve(data);
                }).catch(function(error) {
                    console.log('Looks like there was a problem: \n', error);
                    reject(error);
                });
            })
        }

    })
}