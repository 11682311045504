import * as cookie from "../cookie";
import * as posService from "./posService";
var API = process.env.REACT_APP_ORDERING_DOMAIN + "/";


export function makePayment(token, amount, name, tip) {
    return new Promise((resolve, reject) => {
        let settings = {
            headers: {},
            method: "post",
            async: true,
            mode: "cors",
            cache: "no-cache",
            body: JSON.stringify({
                cardObj: token,
                amount: parseFloat(amount) + parseFloat(tip),
                currency: "USD",
                orderCode: "O00001",
                CustomerID: cookie.get('customerid'),
                orderID: cookie.get('orderid'),
                nameOnCard: name,
                customeremail: "",
                STRIPE_SECRET_KEY: posService.posData.StripeSecretKey,
                STRIPE_PUBLISHABLE_KEY: posService.posData.StripePublishableKey,
            }),
        };
        paymentProcess(true).then(()=>{
            fetch(API + 'stripepayDinein.php', settings)
            .then((res) => res.json())
            .then((response) => {
                posService.call(
                    "UpdateOrderPayment",
                    "post", {}, {}, {
                        req: {
                            APIToken: posService.posData.Code,
                            OrderID: parseInt(cookie.get('orderid')),
                            CustomerID: cookie.get('customerid'),
                            Tip: tip,
                            PaymentGatewayName: "stripe",
                            TransactionID: response.data.stripeResponse.id,
                            PaidAmount: amount + tip,
                        },
                    }
                ).then((res) => {
                    paymentProcess(false).then(()=>{
                        resolve(res.data);
                    });
                });
            });
        })
        
    });
}
export function paymentProcess(status){
    return new Promise((resolve,reject)=>{
        posService.call('PaymentInitiated','get',{},{
            APIToken:posService.posData.Code,
            TableID:cookie.get('tableNo'),
            IsLock :status
        },{}).then(()=>{resolve()})
    })
}

export function cancelPaymentIntent() {
    return new Promise((resolve, reject) => {
        let table = window.table;
        let settings = {
            headers: {},
            method: "post",
            async: true,
            mode: "cors",
            cache: "no-cache",
            body: JSON.stringify({
                "id": table.TransactionToken,
                "STRIPE_SECRET_KEY": posService.posData.StripeSecretKey,
                "STRIPE_PUBLISHABLE_KEY": posService.posData.StripePublishableKey,
            })
        }
        fetch(API + 'cancelPaymentIntent.php', settings)
            .then((res) => res.json())
            .then((res) => {
                if (res.error) {
                    reject(res.error);
                } else {
                    posService.call('CancelAuthorisation', 'post', {}, {}, {
                        req: {
                            "APIToken": posService.posData.Code,
                            "TableID": cookie.get('orderid'),
                        }
                    }).then((res) => {
                        resolve(res)
                    });
                }
            });
    });
}

export function authorizeCard(token, amount, name) {
    return new Promise((resolve, reject) => {
        let table = window.table;

        let settings = {
            headers: {},
            method: "post",
            async: true,
            mode: "cors",
            cache: "no-cache",
            body: JSON.stringify({
                "nameOnCard": name,
                "cardToken": token,
                "amount": amount,
                "currency": 'USD',
                "STRIPE_SECRET_KEY": posService.posData.StripeSecretKey,
                "STRIPE_PUBLISHABLE_KEY": posService.posData.StripePublishableKey
            })
        }
        fetch(API + 'createAndConfirmPaymentIntent.php', settings)
            .then((res) => res.json())
            .then((res) => {
                if (res.error) {
                    reject(res.error);
                } else {
                    posService.call('OpenTapAuthorisation', 'post', {}, {}, {
                        req: {
                            APIToken: posService.posData.Code,
                            OrderID: cookie.get('orderid'),
                            CustomerID: cookie.get('customerid'),
                            "TableID": cookie.get('tableNo'),
                            "PaymentGetway": "stripe",
                            "Token": res.data.stripeResponse.id,
                            "Amount": amount
                        }
                    }).then((res) => {
                        resolve(res);
                    });
                }
            });

    });
}

export function capturePayment(payAmount) {
    return new Promise((resolve, reject) => {
        let table = window.table;
        if (table.IsAuth === 'false') {
            resolve();
        } else {
            let totalAmount = parseFloat(table.TotalAmount);
            let paidAmount = parseFloat(table.TotalPaidAmount);
            let remain = totalAmount - paidAmount;
            console.log('amount to be capture =>', remain);
            if (remain == 0) { //cancel payment
                cancelPaymentIntent().then((res) => {
                    //------- need table update
                    resolve(res);
                })
            } else {
                let settings = {
                    headers: {},
                    method: "post",
                    async: true,
                    mode: "cors",
                    cache: "no-cache",
                    body: JSON.stringify({
                        "id": table.TransactionToken,
                        "amount_to_capture": remain,
                        "STRIPE_SECRET_KEY": posService.posData.StripeSecretKey,
                        "STRIPE_PUBLISHABLE_KEY": posService.posData.StripePublishableKey,
                    })
                }
                fetch(API + 'capturePaymentIntent.php', settings)
                    .then((res) => res.json())
                    .then((res) => {
                        if (res.error) {
                            reject(res.error);
                        } else { //,"PaidAmount": amount
                            posService.call('OpenTapSettlement', 'post', {}, {}, {
                                req: {
                                    "APIToken": posService.posData.Code,
                                    "TableID": cookie.get('tableNo'),
                                    "OrderID": cookie.get('orderid'),
                                    "CustomerID": cookie.get('customerid'),
                                    "AuthToken": table.TransactionToken,
                                    "SettlementID": res.data.stripeResponse.id,
                                    "SettlementAmount": remain
                                }
                            }).then((res) => {
                                resolve(res)
                            });
                        }
                    });
            }
        }



    });




}

export function createRozarPayOrder(amount) {
    return new Promise((resolve, reject) => {
        paymentProcess(true).then(()=>{
            let randomOrderID = (new Date()).getTime();
            fetch(API + 'razorpayorder.php', {
                    headers: {},
                    method: "post",
                    async: true,
                    mode: "cors",
                    cache: "no-cache",
                    body: JSON.stringify({
                        "receipt": randomOrderID,
                        "amount": amount,
                        "currency": window.manifest.Currency,
                        "KEY": posService.posData.RazorpayKeyID,
                        "SECRET": posService.posData.RazorpayKeySecret
                    }),
                })
                .then((res) => res.json())
                .then((response) => {
                    console.log("Rozar Order Response =>", response);
                    resolve(response);
                });
        });
        
    });
}