import * as ecomService from './ecomService';
import * as posService from './posService';
import * as cookie from '../cookie';

export var ecomCart = null;
export var posCart = null;
export var prevPosCart = null;
export var CART = [];
export var priceInfo = null;
export var ecomPriceInfo = null;

var _Observers = [];
export var _cart = new Proxy({ data: [] }, {
    set: function(target, prop, receiver) {
        _Observers.forEach((func) => {
            func();
        });
        return Reflect.get(...arguments);
    }
});



export function Observe(func) {
    _Observers.push(func)
}

export function getPosCart() {
    return new Promise((resolve, reject) => {
        if (posCart != null) {
            resolve(posCart);
        } else {
            posService.call('GetOrderItemDetailsWithAddon', 'get', {}, {
                APIToken: posService.posData.Code,
                TableMasterID: cookie.get('tableNo'),
                CustomerID: cookie.get('customerid'),
                r: (new Date()).getTime()
            }, {}).then((res) => {
                let myPosOrder = [];
                if (res.Result.status === "true") {
                    if (res.Result.OrderList !== undefined && typeof(res.Result.OrderList.PosOrder.OrderItems.Item) !== 'undefined') {
                        var _item = res.Result.OrderList.PosOrder.OrderItems.Item;
                        if (Array.isArray(_item)) {
                            _item.forEach(item => {
                                if (item.Cart_Identifier.search('pi_') !== 0) {
                                    myPosOrder[item.Cart_Identifier] = item;
                                }
                            });
                        } else {
                            myPosOrder[_item.Cart_Identifier] = _item;
                        }
                    }
                }
                
                //prevPosCart = JSON.parse(JSON.stringify(posCart));
                posCart = myPosOrder;
                resolve(myPosOrder);
            })
        }
    });
}
var runningGetCartItems = false;
export function getEcomCart() {
    return new Promise((resolve, reject) => {
        if (ecomCart != null) {
            resolve(ecomCart);
        } else {
            if(runningGetCartItems){
                let watcher = setInterval(()=>{
                    if(!runningGetCartItems){
                        clearInterval(watcher);
                        getEcomCart().then(res=>{resolve(res)})
                    }
                },100)
            } else {
                runningGetCartItems = true;
                ecomService.call('Product/getCartItems', 'POST', {}, { "access_token": "" }, { "identifier": 'ci_' + cookie.get('tableNo') + cookie.get('customerid'), "customer_id": cookie.get('customerid') }).then((res) => {
                    ecomCart = res.data.cartitems;
                    ecomPriceInfo = res.data.priceInfo;
                    _cart.data = res.data.cartitems;
                    runningGetCartItems = false;
                    resolve(ecomCart);
                });
            }
           
        }
    });
}

export function getCart(update) {
    switch (update) {
        case 'UPDATE_POS_CART':
            prevPosCart = JSON.parse(JSON.stringify(posCart));
            posCart = null;
            break;
        case 'UPDATE_ECOM_CART':
            ecomCart = null;
            break;
        case 'UPDATE_BOTH_CART':
            ecomCart = null;
            posCart = null;
            break;
        default :
    }
    return new Promise((resolve, reject) => {
        getPosCart().then(_poscart => {
            getEcomCart().then(__ecomcart => {
                let _ecomcart = JSON.parse(JSON.stringify(__ecomcart))
                let totalQuantity = {};
                let cartCount = 0; //data.cartitems.length;
                let cartItems = []; //data.cartitems;

                _ecomcart.forEach(ci => {
                    if (totalQuantity[ci.id] === undefined) {
                        totalQuantity[ci.id] = ci.cartQuantity;
                    } else {
                        totalQuantity[ci.id] += ci.cartQuantity;
                    }
                    ci.addons = '';
                    if (ci.varients !== undefined) {
                        ci.addons = [];
                        let __addons = {}
                        ci.varients.forEach(v => {
                            if (__addons[v.RootName] == undefined) {
                                __addons[v.RootName] = v.title;
                            } else {
                                __addons[v.RootName] += ', ' + v.title;
                            }
                            ci.addons.push(v.RootName + ':' + v.title);
                        })

                        let _arr = [];
                        (Object.keys(__addons)).forEach(k => {
                            _arr.push(k + ':' + __addons[k])
                        })

                        ci.addons = ci.addons.join(',');
                        ci.addons = _arr.join(',<br/>');
                    }

                    if (_poscart[ci.cartProductIdentifier] === undefined) {
                        cartItems.push(ci);
                        cartCount += parseInt(ci.cartQuantity);
                        ci.previous = false;
                        ci.IsDeleted = "0"
                    } else {
                        if (parseInt(_poscart[ci.cartProductIdentifier].Quantity) == parseInt(ci.cartQuantity)) {
                            ci.previous = true;
                            ci.IsDeleted = _poscart[ci.cartProductIdentifier].IsDeleted
                            cartItems.push(ci);
                        } else {
                            var prev = JSON.parse(JSON.stringify(ci))
                            prev.cartQuantity = parseInt(_poscart[ci.cartProductIdentifier].Quantity);
                            prev.price = prev.baseprice * prev.cartQuantity;
                            prev.previous = true;
                            prev.IsDeleted = _poscart[ci.cartProductIdentifier].IsDeleted
                            cartItems.push(prev);
                            //-------------------
                            ci.cartQuantity -= prev.cartQuantity;
                            ci.price = ci.baseprice * ci.cartQuantity;
                            ci.previous = false;
                            cartItems.push(ci);
                            cartCount += ci.cartQuantity;
                        }
                    }
                });

                cartItems.sort(function(a, b) {
                    if (a.previous) { return 1 } else { return -1 }
                });
                cartItems.forEach(ci => {
                    ci['totalQuantity'] = totalQuantity[ci.id];
                    var instruction = JSON.parse(ci.specialInstruction);
                    var _instruction = JSON.parse(ci.specialInstruction);
                    var prevInstruction = [];

                    if (_poscart[ci.cartProductIdentifier] != undefined) {
                        prevInstruction = JSON.parse(_poscart[ci.cartProductIdentifier].Comments_Json)
                    };
                    prevInstruction.forEach((note) => {
                        _instruction.splice(_instruction.indexOf(note), 1);
                    });

                    if (ci.previous) {
                        let _prod = _poscart[ci.cartProductIdentifier];
                        let prevIns = _prod.Comments.__text;
                        if (prevIns === undefined) {
                            prevIns = _prod.Comments;
                        }
                        ci.specialNote = prevIns;
                    } else {
                        if (_instruction.length > 0) {
                            ci.specialNote = _instruction[0];
                        } else {
                            ci.specialNote = '';
                        }
                    }
                });
                CART = cartItems;

                let deletePrice = {
                    subtotal :0,
                    tax:0,
                    gross:0
                }
                
                cartItems.forEach(product=>{
                    if(product.IsDeleted === '1'){
                        deletePrice.subtotal += product.priceinfo.Gross;
                        deletePrice.tax += product.priceinfo.Taxes;
                        deletePrice.gross += product.priceinfo.Gross + product.priceinfo.Taxes;
                    }
                });
                priceInfo = {
                    subtotal: ecomPriceInfo.subtotal - deletePrice.subtotal,
                    Taxes: ecomPriceInfo.Taxes - deletePrice.tax, 
                    Discounts: ecomPriceInfo.Discounts, 
                    shipping: ecomPriceInfo.shipping, 
                    Gross: ecomPriceInfo.Gross - deletePrice.gross
                }

                resolve(cartItems);
                switch (update) {
                    case 'UPDATE_POS_CART':
                    case 'UPDATE_ECOM_CART':
                    case 'UPDATE_BOTH_CART':
                        _cart.data = __ecomcart;
                        break;
                    default :
                }

                
            });
        })
    })
}
export function __addToCart(cartitems, customerid) {
    return new Promise((resolve, reject) => {
        ecomService.call('Product/AddToCart', 'POST', {}, { "access_token": "" }, {
            cartItems: cartitems,
            "identifier": 'ci_' + cookie.get('tableNo') + customerid,
            "customer_id": customerid
        }).then((res) => {
            getCart('UPDATE_ECOM_CART').then((data) => {
                resolve();
            });
        });
    })
}

export function addToCart(id, varients, quantity, instructions, ) {
    return new Promise((resolve, reject) => {
        ecomService.call('Product/AddToCart', 'POST', {}, { "access_token": "" }, {
            cartItems: [{
                product_id: id,
                varients: varients,
                quantity: quantity,
                specialInstruction: instructions
            }],
            "identifier": 'ci_' + cookie.get('tableNo') + cookie.get('customerid'),
            "customer_id": cookie.get('customerid')
        }).then((res) => {
            ecomCart = null;
            getCart('UPDATE_ECOM_CART').then((data) => {
                resolve(ecomCart);
            });
        })
    })
}

export function removeFromCart(id, cartProductIdentifier) {
    return new Promise((resolve, reject) => {
        ecomService.call('Product/DeleteFromCart', 'POST', {}, { "access_token": "" }, {
            "product_id": id,
            "identifier": 'ci_' + cookie.get('tableNo') + cookie.get('customerid'),
            "cartProductIdentifier": cartProductIdentifier,
            "customer_id": cookie.get('customerid')
        }).then((res) => {
            ecomCart = null;
            getEcomCart('UPDATE_ECOM_CART').then((data) => {
                resolve(ecomCart);
            });

        })
    })
}

export function clearCart() {
    return new Promise((resolve, reject) => {
        ecomService.call('Product/DeleteFromCart', 'POST', {}, { "access_token": "" }, {
            "identifier": 'ci_' + cookie.get('tableNo') + cookie.get('customerid'),
            "customer_id": cookie.get('customerid')
        }).then((res) => {
            ecomCart = null;
            getEcomCart().then((data) => {
                resolve(ecomCart);
            });

        })
    })
}

export function getUserToken(){
    return btoa([
        cookie.get('tableNo'),
        cookie.get('customerid'),
        cookie.get('customerCountryCode'),
        cookie.get('customerMobile'),
        cookie.get('customerName')
    ].join('|'));
}