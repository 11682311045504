export var BUID = 0;
var cookieData = {};
export function set(cname, cvalue, exdays, sp) {
    /* if(typeof app_code == 'undefined'){var path = 'buid='+BUID} else{
        if(typeof is_customdomain != 'undefined' && is_customdomain){
            var path = '/';
        } else {
            var path = '/'+app_code+'/';
        }
    } */

    var path = '/';
    if (typeof window.app_code !== 'undefined') {
        path = '/' + window.app_code + '/';
    }


    if (exdays == undefined) { exdays = 30; }
    if (exdays == 0) {
        var expires = "expires=Thu, 01 Jan 1970 00:00:00 UTC";
    } else {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
    }
    if (sp != undefined) {
        expires = sp;
    }

    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=" + path;
}
export function get(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return null;
}
export function del(cname) {
    set(cname, '', 0);
}
export function clear() {
    var cookies = document.cookie.split(";");
    for (var i = 0; i < cookies.length; i++) {
        del(cookies[i].split("=")[0]);
    }
}
export function spSet(cname, cvalue) {
    cookieData[cname] = cvalue
    set("pwa_" + BUID, JSON.stringify(cookieData));
}
export function spGet(cname, cvalue) {
    if (cookieData[cname] != undefined) {
        return cookieData[cname];
    } else {
        return null;
    }

}
export function spDel(cname, cvalue) {
    delete cookieData[cname];
    set("pwa_" + BUID, JSON.stringify(cookieData));
}
export function spClear() {
    cookieData = {};
    set("pwa_" + BUID, JSON.stringify(cookieData));
}