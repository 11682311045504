import React, { Component } from 'react';
import Topbar from '../topbar/topbar';
import Categories from '../categories/categories';
import * as ecomService from '../../services/ecomService';
import AddToCart from '../addToCart/addToCart';
import Checkbox from '@material-ui/core/Checkbox';
import * as cartService from '../../services/cartService';
import * as dialogService from './../dialog/dialogService';
import CloseIcon from '@material-ui/icons/Close';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Button from '@material-ui/core/Button';
import {withStyles} from '@material-ui/core/styles';
import { CSSTransitionGroup } from 'react-transition-group';
import './css/products.css';

const PrimaryButton = withStyles((theme) => ({
	root: {
	  color: theme.palette.getContrastText(window.theme['primary-600']),
	  backgroundColor: window.theme['primary-600'],
	  fontFamily:'inherit',
	  '&:hover': {
		backgroundColor: window.theme['primary-400'],
	  },
	  '&:disabled': {
		backgroundColor: '#d4d4d4',
	  },
	},
}))(Button);

class Products extends Component {
    state = {
        products: [],
        id: 0,
        customize: null,
        isCustomize: '',
        isDetails:'',
        product:null,
        price: 0,
        varients: []
    }

    constructor() {
        super();
        this.updateVarient = this.updateVarient.bind(this);
        this.showCustomize = this.showCustomize.bind(this);
        this.addToCart = this.addToCart.bind(this);
        this.showDetails = this.showDetails.bind(this);
    }

    fakeAction() {
        dialogService.hide();
    }

    showCustomize(product) {
        console.log("Customize product =>", product);
        this.setState({ isCustomize: 'active', customize: product, price: product.price, varients: [] });
    }

    customizeBtn(product) {
        if (product.ProductVarients.length === 0) {
            return '';
        } else {
            return (
                <div className="customizeBtn">
                    <p className="label">Customizable</p>
                </div>
            )
        }
    }

    price(amount) {
        return window.currencySymbols[window.manifest.Currency] + ' ' + parseFloat(amount).toFixed(2)
    }

    custPrice() {
        return (
            <p className="price">{this.price(this.state.price)}</p>
        )
    }

    productDetails() {
        let product = this.state.customize;
        return <div className="details">
            <p className="name">{product.Productname}</p>
            <CloseIcon onClick={(e) => { this.setState({ isCustomize: '', customize: null, price: 0, varients: [] }) }}/>
        </div>
    }

    varientOptions(varient) {
        varient.options = varient.options.filter(op => op.id !== 0);
        let required = false;
        if (varient.is_required === true) {
            required = true;
        }
        let count = 0;
        return <div className="options">
            {varient.options.map(option => {
                if (count === 1) {
                    required = false;
                }
                count++;
                return (
                    <div className="option" key={option.id}>
                        {(varient.is_multiple === true) ? <Checkbox
                            id={'_' + option.id}
                            defaultChecked={(required) ? true : false}
                            color="primary"
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                            name={'_' + varient.id} onChange={(e) => this.updateVarient()}
                            value={option.id}
                        /> :
                            <input className="addon-radio"
                                id={'_' + option.id}
                                type="radio"
                                defaultChecked={(required) ? true : false}
                                name={'_' + varient.id}
                                value={option.id}
                                onChange={(e) => this.updateVarient()}
                            />

                        }
                        <label htmlFor={'_' + option.id}>{option.title} </label>
                    </div>
                )
            })}
        </div>
    }

    productAddons() {
        let product = this.state.customize;
        return <div>
            {
                product.ProductVarients.map(varient => (
                    <div key={varient.id} className="addon">
                        <h3>{varient.name}</h3>
                        {this.varientOptions(varient)}
                    </div>
                ))
            }
        </div>
    }

    customizeProduct() {
        if (this.state.customize === null) {
            return '';
        } else {
            setTimeout(this.updateVarient, 500)
            return (
                <div>
                    {this.productDetails()}
                    <div className="addons">
                        {this.productAddons()}
                    </div>
                    <div className="actions">
                        <div className="item-total">
                            <p>Item Total:</p>
                            {this.custPrice()}
                        </div>
                        <div className="btns">
                            <PrimaryButton onClick={this.addToCart}>Add to cart</PrimaryButton>
                        </div>
                    </div>
                </div>
            )

        }
    }

    updateVarient = (e, option) => {
        let product = this.state.customize;
        if (product !== null) {

            let allOptions = [];
            let price = product.price;
            product.ProductVarients.forEach(addon => {
                addon.options.forEach(op => {
                    allOptions['_' + op.id] = op;
                });
            });
            let addons = document.querySelectorAll('.addons input');
            let selectedAddons = [];
            addons.forEach(el => {
                if (el.checked) {
                    selectedAddons.push(el)
                }
            });
            let _varients = [];
            selectedAddons.forEach(el => {
                _varients.push(el.value)
                let _option = allOptions['_' + el.value];
                if (_option.pricetype === '+') {
                    price += _option.price
                } else if(_option.pricetype === '%'){
                    price += (_option.price * (product.price/100));
                } else {
                    price -= _option.price 
                }
            });


            this.setState({ varients: _varients, price: price });
        }
    }

    addToCart = (e) => {
        let product = this.state.customize;
        let _varients = [];
        let vids = this.state.varients.sort();
        let identity = [];
        
        vids.forEach((id) => {
            identity.push(id+'-1');
            _varients.push({ id: id, quantity: 1 })
        });

        let cartProductIdentifier =  btoa(product.id +'-'+identity.join('-'));

        let quantity = 0;
        cartService.ecomCart.forEach(_product=>{
            if(_product.cartProductIdentifier === cartProductIdentifier){
                quantity = _product.cartQuantity
            }
        });
        quantity++;
       
        cartService.addToCart(product.id, _varients, quantity, '[""]').then((cartItems) => {
            this.setState({ isCustomize: '', customize: null, price: 0, varients: [] });
            cartService.getCart();
        });
    }

    getProducts(pagination) {
        let data = {
            "access_token": "",
            "start": (pagination !== undefined)?pagination:0,
            "limit": 10
        }
        if (this.props.match.params.id !== undefined) {
            data['category_id'] = this.props.match.params.id
        } else {
            data.productsearch = 1
        }

        let search = window.getURLParameter('search', this.props.location.search);
        if (search !== null) {
            data.name = search;
            data.productsearch = 1
        }

        
        ecomService.getProducts(data).then((response) => {
            let res = response.data;
            let paging = res.Paginations;
            let isRemain = (paging.Total - (paging.Start + paging.limit))>0;
            if(isRemain){
                this.getProducts(paging.Start + paging.limit);
            }
            if(pagination !== undefined){
                this.setState({ products: [...this.state.products,...res.Products] });
            } else {
                this.setState({ products: res.Products });
            }
            
        });

    }

    getActionClass(product){
        let cls = ['action'];
        if(product.imagePath === '' || product.imagePath === null){
            cls.push('no-image')
        } 
        return cls.join(' ');
    }

    showMore (product){
        this.setState({product:product,isDetails:'active'});
    }
    
    getDescription(product){
        let text = product.description.replace( /(<([^>]+)>)/ig, '');
        let length = text.length;
        if(length > 65){
            return (
                <div className="desc" data-text={text}>
                    <span className="text">{text.substr(0,65)}...</span> 
                    <span className="readmore" onClick={(e)=>{this.showMore(product)}}> Show more</span>
                </div>
            )
        } else {
            return text;
        }
    }

    productSlider(product){
        console.log(product);
        if(product.ProductImages.length === 0){
            product.ProductImages.push({imagePath:'/no-image.webp'})
        }
        

        return (
                <Carousel className="slider" showArrows={false} showThumbs={false}>
                    {product.ProductImages.map(image => (
                        <div>
                            <div className="image" style={{ backgroundImage: "url(" + image.imagePath + ")" }}></div>
                        </div>
                    ))}
                </Carousel>
            )
        
    }

    closeDetails(){
        this.setState({isDetails:''});
        setTimeout(()=>{this.setState({product:null})},300)
    }

    productViewDetails(){
        let product = this.state.product;
        //console.log(product)
        if(product == null){
            return '';
        } else {
            let text = product.description.replace( /(<([^>]+)>)/ig, '');
            return (
                <div>
                    {this.productSlider(product)}
                    <CloseIcon className="close" onClick={()=>{this.closeDetails()}} />
                    <p className="title">{product.Productname}</p>
                    <div className="description">
                        <p className="price">Price: {this.price(product.price)}</p>
                        <div className="sep"></div>
                        <p className="desc">{text}</p>
                    </div>
                </div> 
            )
        }  
    }
    
    showDetails(e){
        /* let el_product = e.target.closest('.product');
        el_product.classList.add('details');
        console.log(el_product); */
    }

    render() {
        //this.getProducts();
        return (
            <div>
                <Topbar {...this.props}/>
                <Categories {...this.props}/>

                <div className="products">
                    {this.state.products.map(product => (
                        <div className="product" key={product.id} onClick={(e)=>{this.showMore(product)}}>
                            <div className="block">
                                <div className="info">
                                    {/* <Link to={`/${window.app_code}/product/${product.id}`}><h2>{product.Productname}</h2></Link> */}
                                    <h2 >{product.Productname}</h2>
                                    <p className="price">{this.price(product.price)}</p>
                                    <p className="description">{this.getDescription(product)}</p>
                                </div>
                                <div className={this.getActionClass(product)}>
                                    <div>
                                        {/* <Link to={`/${window.app_code}/product/${product.id}`}>
                                            
                                        </Link> */}
                                        <div className="image" style={{ backgroundImage: "url(" + product.imagePath + ")" }}></div>
                                        <div className="atc-btn">
                                            <AddToCart key={product.id} id={product.id} product={product} {...this.props} customize={this.showCustomize} />
                                        </div>
                                        {this.customizeBtn(product)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                <div className={"customize-popup " + this.state.isCustomize}>
                    <div className="body">{this.customizeProduct()}</div>
                </div>
                                    
                <div className={'product-details '+ this.state.isDetails}>
                    <div className="body">{this.productViewDetails()}</div>
                </div>
            </div>
        );
    }

    componentDidMount() {
        this.setState({ id: this.props.match.params.id });
        this.getProducts();

        /* this.props.history.listen((location, action) => {
            setTimeout(() => {
                this.getProducts();
            }, 200);
        }); */
    }

    componentDidUpdate = (prevProps) => {
        if (this.state.id !== this.props.match.params.id) {
            this.setState({ id: this.props.match.params.id });
            this.getProducts();
        }
        this.props.history.listen((location, action) => {
            this.setState({ id: this.props.match.params.id });
        });
    }

}

export default Products;