import React, { Component } from 'react';
import * as auth from '../../services/authService';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import * as posService from './../../services/posService';
import './css/login.css';

import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CloseIcon from '@material-ui/icons/Close';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import IconButton from '@material-ui/core/IconButton';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const DialogButton = withStyles((theme) => ({
	root: {
	  color: theme.palette.getContrastText(window.theme['primary-600']),
	  backgroundColor: window.theme['primary-600'],
	  fontFamily:'inherit',
	  whiteSpace:'nowrap',
	  '&:hover': {
		backgroundColor: window.theme['primary-400'],
	  },
	  '&:disabled': {
		backgroundColor: '#d4d4d4',
	  },
	},
}))(Button);

const PrimaryButton = withStyles((theme) => ({
	root: {
	  color: theme.palette.getContrastText(window.theme['theme']),
      backgroundColor: window.theme['theme'],
      borderRadius:'50%',
      width:'48px',
      height:'48px',
	  '&:hover': {
		backgroundColor: window.theme['theme'],
	  },
	  '&:disabled': {
		backgroundColor: '#d4d4d4',
	  },
	},
  }))(Button);


const GreenCheckbox = withStyles((theme) => ({
    root: {
        color: window.theme['theme'],
        '&$checked': {
            color: window.theme['theme'],
        },
    }
}))((props)=><Checkbox color="default" {...props} />);


class Login extends Component {

    state = {
        cc: [],
        code: window.manifest.CountryCode,
        tcDialog:false,
        psDialog:false,
        tcContent:'',
        psContent:'',
        acceptTC:false,
        selectCode:false,
        filter:'',
        modeOTP:false,
        loginDisabled:false,
        otpDisabled:false,
        otp:''
    }

    constructor() {
        super();
        this.countryCodeChange = this.countryCodeChange.bind(this);
        //this.renderValue = this.renderValue.bind(this);
        this.showTC = this.showTC.bind(this);
        this.showPP = this.showPP.bind(this);
    }

    getCountries() {
        return new Promise((resolve, reject) => {
            fetch('https://restcountries.eu/rest/v2/all').then((response) => {
                return response.json()
            }).then((data) => {
                //console.log('cc =>', data)
                resolve(data);
            }).catch(function (error) {
                console.log('Looks like there was a problem: \n', error);
                reject(error);
            });

        });
    }

    getCountryCodes() {
        return new Promise((resolve, reject) => {
            this.getCountries().then(data => {
                var cc = [];
                data.forEach(country => {
                    country.callingCodes.forEach(code => {
                        if (code !== ''){
                            let label = `+${code} | ${country.name}`;
                            cc.push({ 
                                label: label.toLowerCase(),
                                value: '+' + code, 
                                name: country.name ,
                                code:'+' + code
                            });
                        }
                            
                    });
                })
                this.setState({ cc: cc });
                resolve(cc);
            });
        });

    }

    handleSubmit = e => {
        e.preventDefault();
        
        let cc = this.state.code;
        let mobile = (document.querySelector('[name="mobile"]')).value;
        let name = (document.querySelector('[name="name"]')).value;

        (document.querySelector('.mobile')).classList.remove('error');
        (document.querySelector('.countryCode')).classList.remove('error');
        (document.querySelector('.tc')).classList.remove('error');

        let validation = true;
        if (cc === '') {
            validation = false;
            (document.querySelector('.countryCode')).classList.add('error');
        }
        if (mobile !== '' && mobile.length > 3 && mobile.length < 12) {

        } else {
            validation = false;
            (document.querySelector('.mobile')).classList.add('error');
        }
        if (validation) {
            
            let tc = (document.querySelector('#tc')).checked;
            if (tc) {
                this.setState({loginDisabled:true})
                auth.login(name, mobile, cc,'').then((status) => {
                    this.setState({modeOTP:true,loginDisabled:false});
                    this.startTimer();
                    (document.querySelector(`[name="otp1"]`)).focus();
                    //this.props.history.push(`/${window.app_code}/products`);
                });
            } else {
                (document.querySelector('.tc')).classList.add('error');
            }

        } 


        return;
    }

    checkUser(){
        if(auth.getUser() != null){
            this.props.history.push(`/${window.app_code}/products`);
        }
    }

    skipAsGuestUser() {
        let tc = (document.querySelector('#tc')).checked;
        if (tc) {
            auth.guestLogin('anonymous', '0000' + (new Date()).getTime(), '+00').then(() => {
                this.props.history.push(`/${window.app_code}/products`);
            });
        } else {
            (document.querySelector('.tc')).classList.add('error');
        }
        //checkUserfromPOS('+00', '0000' + (new Date()).getTime(), 'anonymous');
    }

    countryCodeChange = (e) => {
        this.setState({ code: e });
    }
    setCode(code){
        this.setState({ code: code });
        this.setState({selectCode:false})
    }

    showTC(e) {
        e.preventDefault();
        e.stopPropagation();
        console.log(e)
        posService.call('GetTermsandconditions', 'get', {}, {
            APIToken: posService.posData.Code
        }, {}).then((res) => {
            this.setState({tcContent:res.Result.TermsAndConditions, tcDialog:true})
            /* dialogService.show({
                title: 'Terms and Conditions',
                body: res.Result.TermsAndConditions,
                actions: [{
                    label: 'OK',
                    class: '',
                    action: () => {
                        dialogService.hide();
                    }
                }]
            }) */
        });


    }

    showPP(e) {
        e.preventDefault();
        e.stopPropagation();
        posService.call('GetPrivacySettings', 'get', {}, {
            APIToken: posService.posData.Code
        }, {}).then((res) => {
            this.setState({psContent:res.Result.PrivacySettings, psDialog:true});
            /* dialogService.show({
                title: 'Privacy Settings',
                body: res.Result.PrivacySettings,
                actions: [{
                    label: 'OK',
                    class: '',
                    action: () => {
                        dialogService.hide();
                    }
                }]
            }) */
        });

    }

    renderOptions(props, option, snapshot, className) {
        return (
            <button {...props} className={className} type="button">
                {option.label}
            </button>
        );
    }

    getSkipButton() {
        if(window.table === undefined){
            this.props.history.push(`/${window.app_code}/scan`);
            return;
        }
        if (window.table.PaymentOption === '2') {
            return null;
        }
        return (
            <div>
                <fieldset className="or-line"><legend>OR</legend></fieldset>
                <p className="guest" >Continue as a <span onClick={(e) => { this.skipAsGuestUser() }}>Guest User</span></p>
            </div>
        )
    }

    handleChange = (e)=>{
        this.setState({acceptTC:!this.state.acceptTC})
    }
    codeFilter = e =>{
        this.setState({filter:e.target.value.toLowerCase()})
    }
    selectCode = e =>{
        var tmp = document.createElement("input");
        document.body.appendChild(tmp);
        tmp.focus();
        document.body.removeChild(tmp);
        this.setState({selectCode:true});
        
        setTimeout(()=>{
           (document.querySelector('[name="select-c-code"]')).value = this.state.filter
        },600);
        
    }
    validateOTP = (e) =>{
        //e.preventDefault();
        let otp = this.state.otp;
        let cc = this.state.code;
        let mobile = (document.querySelector('[name="mobile"]')).value;
        let name = (document.querySelector('[name="name"]')).value;
        this.setState({otpDisabled:true})
        auth.login(name, mobile, cc,otp).then((status) => {
            this.setState({otpDisabled:false})
            if(status){
                this.props.history.push(`/${window.app_code}/products`);
            } else {
                (document.querySelector('.otp-box')).classList.add('error');
                setTimeout(()=>{
                    (document.querySelector(`[name="otp1"]`)).value = '';
                    (document.querySelector(`[name="otp2"]`)).value = '';
                    (document.querySelector(`[name="otp3"]`)).value = '';
                    (document.querySelector(`[name="otp4"]`)).value = '';
                    (document.querySelector(`[name="otp1"]`)).focus();
                    (document.querySelector('.otp-box')).classList.remove('error');
                },500)
                //alert('Wrong')
            }
        });
    }
    otpUp = (e) =>{
        let code = e.keyCode;
        let el = e.target;
        let value = el.value;
        let index = parseInt((el.getAttribute('name')).replace('otp',''));

        let _otp = (document.querySelector(`[name="otp1"]`)).value + (document.querySelector(`[name="otp2"]`)).value + (document.querySelector(`[name="otp3"]`)).value + (document.querySelector(`[name="otp4"]`)).value;

        if(code === 8){
            if(this.state.otp === _otp){
                console.log('back')
                if(index !== 1){
                    (document.querySelector(`[name="otp${index-1}"]`)).focus();
                }
            }
            this.setState({otp:_otp})
            return;
        }
        this.setState({otp:_otp});
        if(code !== 9 && value !== ''){
            if(index === 4){
                console.log('end',_otp);
                (document.querySelector(`.login-submit-btn`)).focus();
                setTimeout(()=>{this.validateOTP();},20)
            } else {
                (document.querySelector(`[name="otp${index+1}"]`)).focus();
            }
        }
        
    }
    startTimer(){
        let el = (document.querySelector('.timer'));
        let time = 30;
        el.innerHTML = 30;
        let _this = this;
        let timer = setInterval(()=>{
            el.innerHTML = time--;
            if(time === 0){
                clearInterval(timer);
                _this.endTimer();
            }
        },1000)
    }
    endTimer(){
        this.setState({modeOTP:false});
    }
    render() {
        return (
            <div className="login">
                
                <div className="block">
                    <img className="logo-badge" alt="" src={posService.posData.BannaerImageUrl} />
                    <div className={(this.state.modeOTP)?'login-part off':'login-part'}>
                        <h2>Login</h2>
                        <form onSubmit={this.handleSubmit}>
                            <div className="row phone-number">
                                <div className="">
                                    <div className="input-field countryCode">
                                        <input onFocus={this.selectCode} value={this.state.code} name="countryCode" onChange={()=>{}}/>
                                    </div>
                                </div>
                                <div className="">
                                    <div className="login-input-field mobile">
                                        <input type="number" name="mobile" placeholder="Mobile" autoComplete="off" pattern="[0-9]*" spellCheck="false" />
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div className="login-input-field">
                                    <input type="text" name="name" placeholder="Your Name" autoComplete="off" spellCheck="false" />
                                </div>
                            </div>

                            <div className="row action-area">
                                <div className="row tc">
                                    <GreenCheckbox checked={this.state.acceptTC} onChange={this.handleChange} name="checkedG" id="tc" />
                                    <label htmlFor="tc">I agree with <span className="link" onClick={this.showTC}>Terms &amp; Conditions</span> and <span className="link" onClick={this.showPP}>Privacy Settings</span></label>
                                </div>
                                <PrimaryButton type="submit" disabled={this.state.loginDisabled} className="login-submit-btn"><ArrowForwardIcon fontSize="large"  /></PrimaryButton>
                            </div>
                            
                        </form>
                        {this.getSkipButton()}
                    </div>
                    <div className={(this.state.modeOTP)?'otp-part':'otp-part off'}>
                        <h2>OTP</h2>
                        
                        <div className="otp">
                            <div className="otp-box">
                                <input type="number" onKeyUp={this.otpUp} name="otp1" maxLength={1} placeholder="." autoComplete="off" pattern="[0-9]*" spellCheck="false" />
                                <input type="number" onKeyUp={this.otpUp} name="otp2" maxLength={1} placeholder="." autoComplete="off" pattern="[0-9]*" spellCheck="false" />
                                <input type="number" onKeyUp={this.otpUp} name="otp3" maxLength={1} placeholder="." autoComplete="off" pattern="[0-9]*" spellCheck="false" />
                                <input type="number" onKeyUp={this.otpUp} name="otp4" maxLength={1} placeholder="." autoComplete="off" pattern="[0-9]*" spellCheck="false" />
                            </div>
                            <PrimaryButton type="button" disabled={this.state.otpDisabled} className="login-submit-btn" onClick={this.validateOTP}><ArrowForwardIcon fontSize="large"  /></PrimaryButton>
                        </div>
                        <div className="change-mobile"onClick={()=>{this.setState({modeOTP:false})}}>Change Mobile No</div>
                        <div className="timer">30</div>
                    </div>
                </div>

                <p className="store-name">{window.manifest.name}</p>

                <Dialog
                    open={this.state.tcDialog}
                    TransitionComponent={Transition}
                    keepMounted
                    aria-labelledby="dialog-2-title"
                    aria-describedby="dialog-2-description"
                >
                    <DialogTitle id="dialog-2-title">{"Terms and Conditions"}</DialogTitle>
                    <DialogContent className="text">
                        <DialogContentText id="dialog-2-description">
						{this.state.tcContent}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <DialogButton onClick={()=>{this.setState({tcDialog:false})}} >Ok</DialogButton>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={this.state.psDialog}
                    TransitionComponent={Transition}
                    keepMounted
                    aria-labelledby="dialog-3-title"
                    aria-describedby="dialog-3-description"
                >
                    <DialogTitle id="dialog-3-title">{"Privacy Settings"}</DialogTitle>
                    <DialogContent className="text">
                        <DialogContentText id="dialog-3-description">
						{this.state.psContent}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <DialogButton onClick={()=>{this.setState({psDialog:false})}} >Ok</DialogButton>
                    </DialogActions>
                </Dialog>

                <Dialog fullScreen open={this.state.selectCode} className="select-code"  TransitionComponent={Transition}>
                    <div className="code-container">
                        <div className="code-header">
                        <div className="filter-field">
                            <input type="text" name="select-c-code" onKeyUp={this.codeFilter} placeholder="Country Code | Name" autoComplete="off" spellCheck="false" />
                        </div>
                        <IconButton color="inherit" onClick={()=>{this.setState({selectCode:false})}} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                    </div>
                    
                    <List>
                        {this.state.cc.filter(country => country.label.includes(this.state.filter)).map(filtered => (
                            <ListItem key={filtered.code+filtered.name} button onClick={()=>{this.setCode(filtered.code)}}>
                                <ListItemText primary={filtered.code} secondary={filtered.name} />
                            </ListItem>
                        ))}
                    </List>
                    </div>
                </Dialog>
                

            </div>
        );
    };

    componentDidMount() {
        this.checkUser();
        this.getCountryCodes();
    }

}

export default Login;