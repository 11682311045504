import * as posService from './posService';
import * as cookie from '../cookie';



export function getTable() {
    return cookie.get('tableNo')
}


export function getUser() {
    let user = null
    if (cookie.get('customerid') != null) {
        user = {
            id: cookie.get('customerid'),
            name: cookie.get('customerName'),
            mobile: cookie.get('customerMobile'),
            countryCode: cookie.get('customerCountryCode')
        }
    }
    return user;
}

export function login(name, mobile, countryCode,OTP) {
    return new Promise((resolve, reject) => {
        if (getUser() === null) {
            posService.call('AddCustomerWithOTP', 'post', {}, {}, {
                "acwm": {
                    "APIToken": posService.posData.Code,
                    "Name": name,
                    "Mobile": mobile,
                    "OTP":OTP,
                    "PhoneCountryCode": countryCode,
                    "BrowserID": '1234567890'
                }
            }).then((data) => {
                if(OTP !== ''){
                    if(data.Result.CustomerID !== ''){
                        cookie.set('customerid', data.Result.CustomerID);
                        cookie.set('customerName', data.Result.CustomerName);
                        cookie.set('customerMobile', data.Result.Mobile);
                        cookie.set('customerCountryCode', data.Result.PhoneCountryCode);
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                } else {
                    resolve(true);
                }
                
                
            });
        } else {
            console.log('from cookie');
            resolve();
        }
    })
}
export function guestLogin(name, mobile, countryCode) {
    return new Promise((resolve, reject) => {
        if (getUser() === null) {
            posService.call('AddCustomerWithMobile', 'post', {}, {}, {
                "acwm": {
                    "APIToken": posService.posData.Code,
                    "Name": name,
                    "Mobile": mobile,
                    "PhoneCountryCode": countryCode,
                    "BrowserID": '1234567890'
                }
            }).then((data) => {
                if(data.Result.CustomerID !== ''){
                    cookie.set('customerid', data.Result.CustomerID);
                    cookie.set('customerName', data.Result.CustomerName);
                    cookie.set('customerMobile', data.Result.Mobile);
                    cookie.set('customerCountryCode', data.Result.PhoneCountryCode);
                    resolve(true);
                }
                resolve(true);
                
                
                
            });
        } else {
            console.log('from cookie');
            resolve();
        }
    })
}